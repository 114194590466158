// Note: 'Applications' are referred to as 'Projects' on the frontend to the client.

export default {
  // Form: Expression of interest ===============
  registeredBy: 'Expression of interest registered by',
  dateReceived: 'Date expression of interest received',
  addresses: 'Physical address(es) of property',
  estPropertySizeHa: 'Approximate size of property (hectares)',
  sectors: 'Sector',
  existingFieldAdvisor: 'Name of KMR Field Advisor already working with property (if applicable)',
  personGroupReferral: 'Name of person or group that referred the applicant to KMR (if applicable)',
  applicantDetails: 'Applicant details',
  applicantOrganisation: 'Applicant organisation',
  isRepresentingGroupOrCollective: 'Are you submitting this EOI on behalf of a collective or group',
  groupOrCollective: 'Group or collective name',
  activitiesInterestedIn: 'Land mitigation activities interested in',
  marketingChannel: 'How did you hear about KMR?',
  marketingChannelAdditionalInformation: 'How did you hear about KMR? (additional information)',

  // Form: Decision ===============
  'decision.fieldAdvisors': 'Field advisors',
  assignedReviewer: 'Assigned reviewer',
  meetsCriteria: 'Do you recommend proceeding with this expression of interest?',
  justification: 'Justification for not meeting KMR criteria',
  'justification.label': 'Justification for not meeting KMR criteria',
  decision: 'Decision',

  // Form: Pre-checks ===============
  'preChecks.fieldAdvisors': 'Field advisors',
  'preChecks.type': 'Plan type',
  'fieldAdvisors.label': 'Field advisors',
  fieldAdvisorAssignedDate: 'Date field advisor assigned',
  identifier: 'SRP number',
  proposedVisitDate: 'Proposed date of site visit',
  ownerContacted: 'Landowner contacted',
  mapRiskInspected: 'Property map and risk areas inspected',
  boundariesDrafted: 'Property boundaries drafted',
  wetWeatherGearPacked: 'Wet weather gear packed',
  phoneCharged: 'Mobile phone(s) charged',
  colleagueNotified: 'Buddy informed of trip, intended location and time of return',

  // Form: applicant ===============
  'applicant.addresses': 'Physical address(es) of property',
  propertyIdentifiers: 'Property ID (legal description)',
  applicant: 'Applicant details',
  isApplicantPrimaryContact: 'Is applicant the primary contact?',
  primaryContact: 'Primary contact details',
  primaryContactOrganisation: 'Primary contact organisation',
  primaryContactRelationship: 'Primary contact relationship to land',

  // Form: Property systems & aspirations ===============
  excludedDueToTransferableTitles:
    'Are there any exclusion considerations due to transferable titles (Auckland region only)?',
  transferableTitlesComment: 'Comment on transferable titles',
  isCouncilOrDocLand: 'Is any of the property boundary on council reserve or DOC land?',
  sustainabilityJourneyDescription:
    'Describe the landowner’s journey and aspirations, including for sustainability and cultural practices',
  hasExistingRelationshipWithKaitiakiOrMarae: 'Describe any landowner relationship with kaitiaki and/or local marae',
  // Note, this has not been added, as the label is the same.
  // If this changes, will need to add a propertySystemsAspirations specific label.
  // 'propertySystemsAspirations.sectors': 'Sector',
  'landCovers.landCoverType': 'Land cover type',
  'landCovers.estPercentageCover': 'Approximate percentage cover',
  stockIntensity: 'Stock intensity (if relevant)',
  regionalCouncil: 'Regional council',
  territorialAuthority: 'Territorial authority',
  freshwaterManagementUnit: 'Freshwater management unit',
  'systemAspirations.areaHa': 'Calculated size of property',

  // Form: Catchment context ===============
  surfaceWaterCatchments: 'Surface water catchment(s)',
  relevantAwaWaterways: 'Name of relevant awa/waterways',
  relevantAwaWaterwaysLocalNames: 'Are there any other local names for awa/waterways?',
  ecologicalDistricts: 'Ecological district(s)',
  localMaraes: 'Local marae',
  soilTypes: 'Soil types present',
  generalObservations: 'Describe the general land use and land cover of the local area',
  effectiveGrazingAreaHa: 'Effective grazing area (ha)',

  // Form: Risks & Opportunities ===============
  isLandErodible: 'Does the property fall within highly erodible land use capability classes (LUC 6e and above)?',
  approxPercentErodiblePasture: 'The approximate percentage of property on highly erodible pasture',
  erodibleLandComment: 'Erodible land comment',
  streamBankErosionExists: 'Is there observed stream bank erosion?',
  streamBankErosionLevel: 'Level of observed stream bank erosion',
  streamBankErosionComment: 'Stream bank erosion comment',
  isCoastalMarineArea: 'Is there a coastal boundary?',
  coastalMarineAreaComment: 'Coastal boundary comment',
  culturalSignificance: 'Describe any local wāhi tapu or sites of cultural and/or community significance',
  managementPractices:
    'Describe any good practice in place, and any limitations, risks or opportunities from a management perspective',
  significantKnownWetlandsExist: 'Are there any wetlands?',
  significantKnownWetlandsComment: 'Comment on wetland size and significance',
  isSignificantNaturalArea: 'Are there areas of important indigenous vegetation or habitat?',
  significantNaturalAreaComment: 'Areas of important indigenous vegetation or habitat comment',
  farmForestrySystemsDescription: 'Describe the type of farming/forestry systems that the landowner utilises',
  landUseHistoryDescription: 'Describe the history of land use of this property, if known',
  environmentalStateDescription: 'Describe the history of land use of this property, if known',

  hadPrevFundsForRemProjects: 'Has there been funding provided from Regional councils in the last 5 years?',
  prevFundsDescription: 'Name the funds',
  hasExistingCouncilProjects: 'Are there any past or present regional council projects on the property?',

  // Form: Plan Overview ===============
  actualVisitDate: 'Actual date of first site visit',
  planPriorities: 'Priorities of the sediment reduction plan',
  regulatoryConsiderations:
    'Describe any regional or national regulatory considerations impacting \
    the projects',

  // Form: Application/Project details ===============
  'projectDetails.identifier': 'Project ID',
  fundingType: 'Funding type',
  hasAdditionalFundingTags: 'Add additional funding or community group tags',
  additionalFundingTags: 'Additional funding tags',
  fundingTaggedThirdPartyName: 'Name of tagged third party or community group',
  startYear: 'Anticipated start of works',
  endYear: 'Anticipated end of works',
  estimatedFreightCost: 'Estimated freight costs for delivery of plants',
  'proposedTotals.proposedGatesUnits': 'Total gate units',
  'proposedTotals.proposedGatesCost': 'Total gate cost',
  'proposedTotals.proposedTroughUnits': 'Total trough units',
  'proposedTotals.proposedTroughCost': 'Total trough cost',
  'proposedTotals.fencingLength': 'Total fencing length (m)',
  'proposedTotals.fencingCost': 'Total fencing cost',
  'proposedTotals.plantingArea': 'Total planting area (ha)',
  'proposedTotals.plantingPlantCost': 'Total planting plant cost',
  'proposedTotals.plantingLabourCost': 'Total planting labour cost',
  'proposedTotals.plantingPlantCount': 'Total number of plants',
  'proposedTotals.totalPlantingArea': 'Total planting area (m2)',
  'proposedTotals.plantingCost': 'Total planting cost',
  'proposedTotals.siteMaintenanceArea': 'Total site maintenance area (ha)',
  'proposedTotals.siteMaintenanceCost': 'Total site maintenance cost',
  'proposedTotals.sitePreparationArea': 'Total site preparation area (ha)',
  'proposedTotals.sitePreparationCost': 'Total site preparation cost',
  'proposedTotals.totalCoFunderContribution': 'Other fund contribution',
  hasCoFunders: 'Is there a co-funding contribution?',
  coFunders: 'Co-funder',
  'coFunders.name': 'Co-funding: Provider',
  'coFunders.contribution': 'Co-funding: Amount contributed',
  percentageLandownerContribution: 'Percentage contribution landowner',
  percentageKmrContribution: 'Percentage contribution KMR',
  hasAlreadyBeenReviewed: 'Has the record already been reviewed?',
  planDescription: 'Brief description of the works in scope of this project, and high level future plans',
  hasAdditionalCosts: 'Are there other project related costs that KMR will contribute to?',
  additionalCosts: 'Additional project cost',
  'additionalCosts.type': 'Additional project cost: Type',
  'additionalCosts.cost': 'Additional project cost: Cost',
  'additionalCosts.reason': 'Additional project cost: Rationale',
  'sitePreparationActions.type': 'Action subtype',
  'sitePreparationActions.area': 'Area',
  'sitePreparationActions.proposedCost': 'Cost ($)',
  totalNumberOfPlants: 'Total number of plants',
  totalNumberOfPlantsZoneA: 'Total number of plants for Zone A',
  totalNumberOfPlantsZoneB: 'Total number of plants for Zone B',
  totalNumberOfPlantsZoneC: 'Total number of plants for Zone C',
  'plantingActions.type': 'Action subtype',
  'plantingActions.area': 'Area (m2)',
  'plantingActions.proposedCost': 'Cost ($)',
  'plantingActions.numberOfPlants': 'Total plants',
  'plantingActions.numberOfPlantsZoneA': 'Zone A plants (if known/required)',
  'plantingActions.numberOfPlantsZoneB': 'Zone B plants (if known/required)',
  'plantingActions.numberOfPlantsZoneC': 'Zone C plants (if known/required)',
  'gateTroughActions.type': 'Action subtype',
  'gateTroughActions.unitCount': 'Number of units',
  'gateTroughActions.proposedCost': 'Cost ($)',
  'fencingActions.type': 'Action subtype',
  'fencingActions.length': 'Length (m)',
  'fencingActions.fenceType': 'Fence type',
  'fencingActions.proposedCost': 'Cost',
  totalAdditionalCost: 'Total project related costs',
  'maintenanceSprayingActions.type': 'Action subtype',
  'maintenanceSprayingActions.area': 'Area (m2)',
  'maintenanceSprayingActions.proposedCost': 'Cost ($)',

  // Form: Submission ===============
  'proposedTotals.totalProposedCost': 'Total cost of works',
  'proposedTotals.landOwnerContribution': 'Landowner contribution',
  'proposedTotals.kmrContribution': 'KMR contribution',

  // Form: Action
  'action.justification': 'Justification',
  actionType: 'Action',
  actionSubtype: 'Action subtype',
  application: 'Project',
  locationName: 'Location Name',
  regenerationLocationNotes: 'Regeneration location notes',
  anticipatedStart: 'Anticipated start',
  intendedImplementer: 'Work intended to be completed by',
  unitCount: 'Number of units',
  numberOfPlantsRequiringSpraying: 'Number of plants requiring spray',
  sprayCount: 'Number of times to be sprayed',
  fenceType: 'Fence type',
  terrainDifficulty: 'Terrain difficulty',
  plantingDifficulty: 'Planting difficulty',
  plantingType: 'Planting type',
  silvopasturePlantingMethod: 'Planting method',
  actualNumberOfPlants: 'Actual number of plants put in ground',
  averagePricePerPlant: 'Average price per plant ($)',
  maintenanceType: 'Maintenance type',
  sitePreparation: 'Site preparation',
  length: 'Length (m)',
  area: 'Area (m2)',
  rate: '$/metre',
  plantingZoneAWetlandPercent: 'Percentage Zone A Water Edge',
  plantingZoneBLowerBankPercent: 'Percentage Zone B Lower Bank',
  plantingZoneCTransitionPercent: 'Percentage Zone C Transition',
  plantingSpacingStemsPerHa: 'Plant spacing (stems per hectare)',
  numberOfPlants: 'Number of plants',
  numberOfPlantsZoneA: 'Number of plants for Zone A',
  numberOfPlantsZoneB: 'Number of plants for Zone B',
  numberOfPlantsZoneC: 'Number of plants for Zone C',
  numberOfPlantsZoneD: 'Number of plants for Hill Country planting',
  estimatedPricePerHa: 'Estimated total price per hectare',
  kmrContributionPerHa: 'KMR contribution per hectare',
  plantingSpeciesComposition: 'Species composition',
  plantingManagementIntentions: [
    'Management plan - please provide project-specific details including actions',
    'needed to prepare for and maintain project works (e.g. weed control, pest control).',
  ].join(' '),
  'actionPlanting.materialCost': 'Plant cost',
  'actionFencing.materialPrice': '$ per meter',
  'actionGateTrough.materialCost': '$ per unit',
  labourCost: 'Labour cost',

  // Form: Panelist review
  dateReviewed: 'Date of review',
  priceEffectiveDate: 'Pricing applied from',
  assignedMaurikuraTeamMember: 'Assigned Maurikura team member',
  maurikuraChangesRequested: 'Are any changes required by the Field Advisor?',
  summaryOfMaurikuraRequestedChanges: 'Summary of changes required',
  reviewCommentsFromForestryAdvisor: 'Review comments from registered forestry advisor (if relevant)',
  meetsPanelReviewThreshold: 'Does the project meet the threshold for panel review?',
  panelReviewReason: 'Reason for panel review',
  notifyPanelMembers: 'Notify panel members?',
  feedbackRequiredBy: 'Date panel feedback required by',
  panelChangesRequested: 'Are any changes required by the Field Advisor following panel feedback?',
  summaryOfPanelRequestedChanges: 'Summary of changes required to address panel feedback',
  panelist: 'Panelist name',
  generalNotes: 'General notes from panelist',
  areActionsEffective: 'Will these remediation actions reduce sediment loss into the Kaipara?',
  planEffectivenessNotes: 'Why might these actions fail to reduce sediment loss?',
  hasConcernsWithWetlandProtection: 'Are there any concerns with wetland protection?',
  wetlandProtectionChanges: 'What changes are need to protect wetlands?',
  arePlantingChangesRecommended: 'Are any planting changes recommended?',
  areFencingChangesRecommended: 'Are any fencing changes recommended?',
  areGatesTroughsChangesRecommended: 'Are any gate and trough changes recommended?',
  'plantingRecommendedVariations.feature': 'Action',
  'plantingRecommendedVariations.recommendedChange': 'Recommended change',
  'fencingRecommendedVariations.feature': 'Action',
  'fencingRecommendedVariations.recommendedChange': 'Recommended change',
  'gateTroughRecommendedVariations.feature': 'Action',
  'gateTroughRecommendedVariations.recommendedChange': 'Recommended change',

  // Form: Approval
  totalProposedCost: 'Total cost of works',
  finalDecision: 'Final project decision',
  dateCommitted: 'Date committed',
  delegatedAuthority: 'Assigned delegated authority',
  recommendationFromKmr: 'Recommendation from KMR Maurikura',
  coFunderTotal: 'Total amount contributed by co-funders',

  // Form: Nursery supply
  proposedPlantCost: 'Proposed plant cost',
  actualPlantCost: 'Actual planting cost',
  nurserySuppliers: 'Nursery',
  supplyAssignedMaurikuraTeamMember: 'Assigned Maurikura team member',
  dateNurseryContacted: 'Date nursery contacted',
  nurserySupplyComment: 'Comments on nursery supply',
  totalPlantCost: 'Total plant cost',
  totalLabourCost: 'Total labour cost',
  totalPlantingCost: 'Total planting cost',

  // Form: Transfer action
  transferToSubsequentApplication: 'Transfer to subsequent project?',
  subsequentApplication: 'Transfer to project',

  // Form: Contract details
  contractAssignedMaurikuraTeamMember: 'Assigned Maurikura team member',
  contractSent: 'Has the contract been sent to the landowner?',
  dateContractSent: 'Date contract sent to landowner',
  contractUrl: 'Contract URL',
  contractReturned: 'Has the signed contract been returned by the landowner?',
  dateContractReceived: 'Date signed contract received',
  dateContractEnds: 'Date contract ends',

  // Form: Progress
  siteVisitNote: 'Summary of contact with landowner',
  'signOff.fieldAdvisors': 'Field advisors',

  // Form: Sign Off - Assessment summary
  totalActualCost: 'Total actual cost',
  observationDetails: 'Observation details',
  'observationDetails.observationType': 'Observation type',
  'observationDetails.dateAssessmentCompleted': 'Date of assessment',
  'observationDetails.generalNotes': 'General notes and observations',

  // Form: Payment - Request for payments form
  'totalCostsSummary.totalProposedCost': 'Total planned project costs',
  'totalCostsSummary.totalActualCost': 'Total actual project costs',
  'totalCostsSummary.totalAgreedKmrContribution': 'Total agreed KMR contribution',
  'totalCostsSummary.totalKmrPaymentAmount': 'KMR payments to date',

  // Form: Action verification
  inspectionDate: 'Inspection date',
  completionStatus: 'Completion status',
  completionDetails: 'Completion details',
  hasVariation: 'Was there a variation to the planned action?',
  'actionVerification.length': 'Actual length (m)',
  proposedLength: 'Proposed length (m)',
  'actionVerification.area': 'Actual area (m2)',
  proposedArea: 'Proposed area (m2)',
  actualCost: 'Actual cost',
  actualFenceType: 'Actual fence type',
  actualLength: 'Actual metres of fencing',
  actualMaintenanceType: 'Actual maintenance type',
  estimatedPlantSurvivalPercentage: 'Estimated % of plant survival at 2-3 weeks after planting',

  // Form: Payments - Request for payments subform
  'applicant.firstName': 'First name',
  'applicant.lastName': 'Last name',
  'applicant.phone': 'Phone number',
  'applicant.email': 'Email',
  'applicant.postalAddress': 'Postal address',
  'applicantOrganisation.type': 'Type',
  'applicantOrganisation.tradingName': 'Organisation name',
  'applicantOrganisation.gstNumber': 'GST number',
  'applicantOrganisation.nzbn': 'Business or trust ID (optional)',
  kmrPaymentIdentifier: 'Mātai Onekura payment ID',
  smartyGrantsPaymentIdentifier: 'Smarty Grants payment ID',
  smartyGrantsField2: 'Smarty Grants field 2',
  smartyGrantsField3: 'Smarty Grants field 3',
  paymentReason: 'Payment for',
  paymentComments: 'Payment comments',
  invoiceNumber: 'Invoice number',
  paymentRegistered: 'Payment registered',
  kmrPaymentAmount: 'KMR payment amount (GST exclusive)',
  authorisedBy: 'Authorised by',
  dateContractEndsVariation: 'Contract end date variation',
  dateContractEndsVariationDate: 'Variation of contract end date',
} as Record<string, string>
