import { FormIdTypes } from '../forms/types'
import { RouteMode } from '../types/route'

export enum ErrorType {
  REQUIRED = 'required',
  CUSTOM = 'custom',
}

export interface CoreError {
  errorMessage: string
  errorCode: string
}

export interface FieldError<T = object> extends CoreError {
  field: keyof T
}

export type FormErrors<T> = Record<keyof T, CoreError[]>

export type FormStatus = 'unstarted' | 'started' | 'closed' | 'reopened'

export interface FormState<T> {
  objectId?: string
  errors: FieldError<T>[]
  state: FormStatus
}

export interface SectionState<T> {
  forms: {
    [formLabel: string]: FormState<T> | FormState<T>[]
  }
}

export interface WorkflowModelSectionState<T> {
  [section: string]: SectionState<T>
}

export interface WorkflowModelState<T> {
  state: WorkflowModelSectionState<T>
  meta: Record<string, string | boolean>
}

export interface WorkflowState<T> {
  [workflow: string]: {
    [id: string]: WorkflowModelState<T>
  }
}

export type BuiltFormError = {
  id: string
  formId: string
  formLabel: string
  objectId?: string
  url?: string | null
  errors: string[]
}

export type PathFunc = (idParts: (string | undefined)[], mode: RouteMode) => string | null

export type WorkflowFormConfig = {
  label: string
  pathFunc: PathFunc
  subforms?: { [formId: string]: WorkflowFormConfig }
  unstartedFormError?: string
  isRequired?: boolean
}

export type WorkflowFormsConfig = {
  [formId: string]: WorkflowFormConfig
}

export interface WorkflowSectionConfig {
  label: string
  configFunc?: <T = unknown>(idParts: (string | undefined)[], state?: SectionState<T>) => { adviceText?: string }
  forms: WorkflowFormsConfig
}

export interface WorkflowConfig {
  [sectionId: string]: WorkflowSectionConfig
}

export type Workflow = 'expressionOfInterest' | 'plan' | 'application' | 'review' | 'supply' | 'progress' | 'payment'
export type Section =
  | 'expressionOfInterest'
  | 'planCreation'
  | 'application'
  | 'details'
  | 'review'
  | 'supply'
  | 'progress'
  | 'payment'

export const WORKFLOWS_CONFIG: Record<Workflow, WorkflowConfig> = {
  expressionOfInterest: {
    expressionOfInterest: {
      label: 'Expression of Interest',
      forms: {
        newRegistration: {
          label: 'New registration',
          pathFunc: ([id], mode) => (id != null ? `/plans/${mode}/${id}/eoi/registration` : '/plans/new'),
        },
      },
    },
    review: {
      label: 'Review',
      configFunc: ([id]) => (id == null ? { adviceText: 'Not yet started' } : {}),
      forms: {
        decision: {
          label: 'Decision',
          pathFunc: ([id], mode) => `/plans/${mode}/${id}/eoi/decision`,
        },
      },
    },
  },
  plan: {
    planCreation: {
      label: 'Plan',
      forms: {
        preChecks: {
          label: 'Pre-checks',
          pathFunc: ([planId], mode) => `/plans/${mode}/${planId}/plan/pre-checks`,
        },
        applicant: {
          label: 'Applicant',
          pathFunc: ([planId], mode) => `/plans/${mode}/${planId}/plan/applicant`,
        },
        catchmentContext: {
          label: 'Catchment context',
          pathFunc: ([planId], mode) => `/plans/${mode}/${planId}/plan/catchment-context`,
        },
        propertySystemsAspirations: {
          label: 'Property systems & aspirations',
          pathFunc: ([planId], mode) => `/plans/${mode}/${planId}/plan/property-systems-aspirations`,
        },
        systemAspirations: {
          label: 'Risks & opportunities',
          pathFunc: ([planId], mode) => `/plans/${mode}/${planId}/plan/system-aspirations`,
        },
        overview: {
          label: 'Plan overview',
          pathFunc: ([planId], mode) => `/plans/${mode}/${planId}/plan/overview`,
        },
      },
    },
  },
  application: {
    application: {
      label: 'Project ',
      forms: {
        details: {
          label: 'Details & budget',
          pathFunc: ([planId, appId], mode) => `/plans/${mode}/${planId}/plan/project/${appId}/details`,
          subforms: {
            action: {
              label: 'Action',
              pathFunc: ([planId, appId, actionId], mode) => {
                if (!actionId) return `/plans/${mode}/${planId}/map`
                return `/plans/${mode}/${planId}/plan/project/${appId}/action/${actionId}`
              },
              unstartedFormError: 'At least one action is required.',
            },
          },
        },
      },
    },
  },
  review: {
    review: {
      label: 'Review',
      forms: {
        review: {
          label: 'Review',
          pathFunc: ([planId, appId], mode) => `/plans/${mode}/${planId}/review/project/${appId}/review`,
          subforms: {
            panelistReview: {
              label: 'Panelist review',
              pathFunc: ([planId, appId, reviewId], mode) => {
                if (!reviewId) return null
                return `/plans/${mode}/${planId}/review/project/${appId}/review/panelist-review/${reviewId}`
              },
            },
          },
        },
        approval: {
          label: 'Approval',
          pathFunc: ([planId, appId], mode) => `/plans/${mode}/${planId}/review/project/${appId}/approval`,
        },
      },
    },
  },
  supply: {
    supply: {
      label: 'Supply',
      forms: {
        nursery: {
          label: 'Nursery supply',
          pathFunc: ([planId, appId], mode) => `/plans/${mode}/${planId}/supply/project/${appId}/nursery`,
        },
        contract: {
          label: 'Contract details',
          pathFunc: ([planId, appId], mode) => `/plans/${mode}/${planId}/supply/project/${appId}/contract`,
        },
      },
    },
  },
  progress: {
    progress: {
      label: 'Progress',
      forms: {
        signOff: {
          label: 'Sign off',
          pathFunc: ([planId, appId], mode) => `/plans/${mode}/${planId}/progress/project/${appId}/sign-off`,
          subforms: {
            actionVerification: {
              label: 'Action Verification',
              pathFunc: ([planId, appId, reviewId], mode) => {
                if (!reviewId) return null
                return `/plans/${mode}/${planId}/progress/project/${appId}/action-verification/${reviewId}`
              },
            },
          },
        },
      },
    },
  },
  payment: {
    payment: {
      label: 'Payments',
      forms: {
        requestsForPaymentSummary: {
          label: 'Request for payments',
          pathFunc: ([planId, appId], mode) => `/plans/${mode}/${planId}/payments/project/${appId}/payments`,
          subforms: {
            requestsForPayment: {
              label: 'Request for payment',
              pathFunc: ([planId, appId, paymentId], mode) => {
                if (!paymentId) return null
                return `/plans/${mode}/${planId}/payments/project/${appId}/payments/${paymentId}`
              },
              isRequired: false,
            },
          },
        },
      },
    },
  },
}

export interface WorkflowLocators {
  workflowId: Workflow
  sectionId: Section
  formId: FormIdTypes
  instanceId: string
  subFormId?: string
}
