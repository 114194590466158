import { InlineNotification, LoadingIndicator, RecordContent } from '@msaf/core-react'
import { Outlet, useParams } from 'react-router-dom'
import { useViewRedirectGatekeeper } from '../../../../components/gatekeeper'
import ApplicationProvider from '../../../../contexts/application-context'
import { ApplicationStatusFields } from './application/[applicationId]/submission'
import { useWorkflowGatekeeper } from '../../../../forms/application/utils'
import { useFormData } from '../../../../hooks/use-form-state'
import { EDIT_APPLICATION_PROGRESS_PERMISSIONS, UserPermissions } from '../../../../types/permissions'
import { ApplicationRouteParams } from '../../../../types/route'
import { useRequest } from '../../../../hooks/useRequest'
import { useQuery } from 'react-query'
import { useMemo } from 'react'
import { useAddGenerateExportMenu } from '../../../../hooks/use-add-generate-export-menu'

export const ProgressWrapper = () => {
  const { id: planId, applicationId, mode } = useParams<ApplicationRouteParams>()
  const { client } = useRequest()

  const permissions_url = `/api/plan/${planId}/permissions`
  const { data: permissions } = useQuery<{ canEdit: boolean }>([permissions_url], () =>
    client.get(permissions_url).then((response) => response.data),
  )

  const planPermissions = useMemo(() => ({ canEditPlan: !!permissions?.canEdit }), [permissions])
  const canEdit = planPermissions?.canEditPlan
    ? [...EDIT_APPLICATION_PROGRESS_PERMISSIONS, UserPermissions.ASSIGN_PROGRESS]
    : []

  const applicationState = useFormData<ApplicationStatusFields>(`/api/plan/${planId}/project/${applicationId}/status`)

  useViewRedirectGatekeeper(canEdit, UserPermissions.VIEW_PROGRESS)

  useAddGenerateExportMenu(applicationState.data)

  const { isLoading, open } = useWorkflowGatekeeper(planId, applicationId, 'progress', mode)

  if (isLoading) return <LoadingIndicator />

  if (!open) {
    return (
      <RecordContent>
        <InlineNotification isDismissable={false}>Not contracted</InlineNotification>
      </RecordContent>
    )
  }

  return (
    <ApplicationProvider applicationState={{ ...applicationState.data }}>
      <Outlet context={planPermissions} />
    </ApplicationProvider>
  )
}
