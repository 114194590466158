import {
  InputWidth,
  ReadOnlyField,
  SelectType,
  Typeahead,
  TypeaheadOption,
  TypeaheadOptionsProps,
} from '@msaf/core-react'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useRequest } from '../hooks/useRequest'

// TODO: Break these props down into more manageable chunks w/ docs
export interface LookupSearchProps extends Partial<Omit<TypeaheadOptionsProps, 'value' | 'setValue'>> {
  lookupId: string
  fieldFilters?: Record<string, string | number | boolean | null>
  value?: TypeaheadOption
  setValue: (value: TypeaheadOption) => void
  token?: string
  isClearable?: boolean
  isSkeleton?: boolean
  showNameAndDescription?: boolean
  selectType?: SelectType
  validationMessages?: Array<string>
  fieldSize?: InputWidth
  labelledBy: string
  defaultToFirst?: boolean
  excludeValues?: string[]
}

interface LookupFieldOption extends TypeaheadOption {
  description: string
}

export default function LookupSearch({
  lookupId,
  fieldFilters,
  value,
  setValue,
  labelledBy,
  isClearable,
  isSkeleton,
  showNameAndDescription,
  isInvalid,
  selectType,
  isDisabled,
  validationMessages,
  fieldSize,
  defaultToFirst,
  excludeValues,
  ...props
}: LookupSearchProps) {
  const [options, setOptions] = useState<TypeaheadOption[]>([])
  // TODO: Extract the data fetch so this component can be generic
  // Fetch data
  const { client } = useRequest()
  const { isLoading } = useQuery<LookupFieldOption[], Error>(
    ['lookup', lookupId, fieldFilters],
    async () => {
      const queryString = Object.entries(fieldFilters ?? {}).reduce((q, [k, v]) => `${q}&${k}=${v}`, '')
      const { data } = await client.get<LookupFieldOption[]>(`/api/lookup?field=${lookupId}${queryString}`)
      return data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !isDisabled,
      onSuccess: (data: LookupFieldOption[]) => {
        const lookupOptions = data.map(({ label, description, value, ...rest }) => ({
          label: showNameAndDescription ? `${label} - ${description}` : label,
          value: value,
          ...rest,
        }))

        // If no value is set and its configured to select the first value by default
        if (!value?.value && !!lookupOptions.length && defaultToFirst) {
          setValue(lookupOptions[0])
        }

        setOptions(lookupOptions)
      },
    },
  )

  if (isDisabled) {
    return <ReadOnlyField isSkeleton={isLoading || isSkeleton} labelledBy={labelledBy} value={value?.label} />
  }

  return (
    <>
      <Typeahead
        isInvalid={isInvalid}
        selectType={selectType ?? 'default'}
        labelledBy={labelledBy}
        value={value}
        options={options.filter((option) => !excludeValues?.includes(option.value))}
        handleChange={(option) => {
          option !== null && setValue(option)
        }}
        isSkeleton={isSkeleton || isLoading}
        isClearable={isClearable}
        menuPlacement='auto'
        isDisabled={isDisabled}
        fieldSize={fieldSize}
        {...props}
      />
      {validationMessages?.map((errorMessage, idx) => (
        <div key={`${idx}--validation-msg`} className='c-form-field__validation-msg' role='alert'>
          {errorMessage}
        </div>
      ))}
    </>
  )
}
