import { MapUIConfig, SupportedFeature } from '@msaf/maps-common'
import * as L from 'leaflet'
import 'leaflet.markercluster'
import markerIcon from '../../assets/map-pin-solid.svg'
import { LINZ_KEY } from '../environment'
import { FeatureForm } from '../forms/feature'
import { mapboxLayers, mapboxBasemaps, defaultLayerNames } from './mapbox/layers'

export const LINZ_BASE_MAP_URL = 'https://basemaps.linz.govt.nz/v1/tiles/aerial/3857/{z}/{x}/{y}.png'

export const defaultIcon = new L.Icon({
  iconUrl: markerIcon,
  iconSize: new L.Point(25, 30),
}) as L.Icon<L.IconOptions>

export const selectedIcon = new L.Icon({
  iconUrl: markerIcon,
  iconSize: new L.Point(50, 60),
  iconAnchor: new L.Point(25, 40),
}) as L.Icon<L.IconOptions>

/*
This needs to return the tile layer each time otherwise if the same layer
is added to multiple maps things break.
*/
const linzBasemap = () =>
  L.tileLayer(`${LINZ_BASE_MAP_URL}?api=${LINZ_KEY}`, {
    maxZoom: 20,
    attribution: [
      '© <a href="//www.linz.govt.nz/linz-copyright">LINZ CC BY 4.0</a>',
      '© <a href="//www.linz.govt.nz/data/linz-data/linz-basemaps/data-attribution">Imagery Basemap contributors</a>',
    ].join(' '),
  })

export const getBaseLayers = () => L.layerGroup([linzBasemap()])

export const getLayersConfig = () => {
  let baseMaps: L.Control.LayersObject = { LINZ: linzBasemap(), ...mapboxBasemaps() }
  if (Object.keys(baseMaps).length === 1) {
    // No need to display basemaps in layer controls, can't toggle if only one
    baseMaps = {}
  }

  const layers: L.Control.LayersObject = mapboxLayers()
  const defaultLayers = defaultLayerNames()
    .map((layerName) => layers[layerName])
    .filter((layer) => !!layer)
  const layerControl = L.control.layers(baseMaps, layers, { position: 'bottomright', autoZIndex: false })

  return {
    control: layerControl,
    defaultBasemap: linzBasemap(),
    defaultLayers,
  }
}

export const DEFAULT_FEATURE_ZOOM_LEVEL = 9
export const DEFAULT_CENTER: [number, number] = [-36.382220418435416, 174.2441672114744]
const FENCING_LINE_WEIGHT = 2
const DEFAULT_POLYGON_OPTIONS = {
  fillOpacity: 0.3,
  weight: 2,
}

export type FeatureType =
  | 'propertyBoundary'
  | 'gate'
  | 'trough'
  | 'riparianPlanting'
  | 'wetlandPlanting'
  | 'coastalPlanting'
  | 'hillCountryPlanting'
  | 'hillCountryNativeRegeneration'
  | 'hillCountryAlternativeForestry'
  | 'hillCountrySilvopasturePlanting'
  | 'sitePreparation'
  | 'sitePreparationSpotSpraying'
  | 'maintenanceSpraying'
  | 'maintenanceSpotSpraying'
  | 'fundedFencing'
  | 'existingFencing'

export type KMRFeatureConfig = {
  [k in FeatureType]: SupportedFeature
}

export type KMRMapUIConfig = Omit<MapUIConfig, 'featureConfig'> & {
  featureConfig: KMRFeatureConfig
}

export const MAP_CONFIG: Readonly<KMRMapUIConfig> = {
  basemapLayers: [{ type: 'linz', url: '' }],
  crs: L.CRS.EPSG3857,
  featureConfig: {
    propertyBoundary: {
      geometryType: 'Polygon',
      label: 'Property boundary',
      categoryKey: 'propertyBoundary',
      borderColor: '#FFE600',
      noFill: true,
      zIndex: -100,
      weight: DEFAULT_POLYGON_OPTIONS.weight,
    },
    gate: {
      geometryType: 'Point',
      label: 'Gate',
      categoryKey: 'gateTrough',
      initials: 'G',
      markerStyle: 'gate',
      renderContent: (props) => FeatureForm({ featureType: 'gate', ...props }),
      zIndex: 30,
    },
    trough: {
      geometryType: 'Point',
      label: 'Trough',
      categoryKey: 'gateTrough',
      initials: 'T',
      markerStyle: 'trough',
      renderContent: (props) => FeatureForm({ featureType: 'trough', ...props }),
      zIndex: 30,
    },
    riparianPlanting: {
      geometryType: 'Polygon',
      label: 'Riparian planting',
      categoryKey: 'planting',
      borderColor: '#02b129',
      renderContent: (props) => FeatureForm({ featureType: 'riparianPlanting', ...props }),
      zIndex: 20,
      ...DEFAULT_POLYGON_OPTIONS,
    },
    wetlandPlanting: {
      geometryType: 'Polygon',
      label: 'Wetland planting',
      categoryKey: 'planting',
      borderColor: '#5a6e9b',
      renderContent: (props) => FeatureForm({ featureType: 'wetlandPlanting', ...props }),
      zIndex: 20,
      ...DEFAULT_POLYGON_OPTIONS,
    },
    coastalPlanting: {
      geometryType: 'Polygon',
      label: 'Coastal planting',
      categoryKey: 'planting',
      borderColor: '#009FD1',
      renderContent: (props) => FeatureForm({ featureType: 'coastalPlanting', ...props }),
      zIndex: 20,
      ...DEFAULT_POLYGON_OPTIONS,
    },
    hillCountryPlanting: {
      geometryType: 'Polygon',
      label: 'Hill country - native planting',
      categoryKey: 'planting',
      borderColor: '#724B00',
      renderContent: (props) => FeatureForm({ featureType: 'hillCountryPlanting', ...props }),
      zIndex: 20,
      ...DEFAULT_POLYGON_OPTIONS,
    },
    hillCountryNativeRegeneration: {
      geometryType: 'Polygon',
      label: 'Hill country - native regeneration',
      categoryKey: 'planting',
      borderColor: '#0c4d02',
      renderContent: (props) => FeatureForm({ featureType: 'hillCountryNativeRegeneration', ...props }),
      ...DEFAULT_POLYGON_OPTIONS,
    },
    hillCountryAlternativeForestry: {
      geometryType: 'Polygon',
      label: 'Hill country - alternative forestry',
      categoryKey: 'planting',
      borderColor: '#b07303',
      renderContent: (props) => FeatureForm({ featureType: 'hillCountryAlternativeForestry', ...props }),
      zIndex: 20,
      ...DEFAULT_POLYGON_OPTIONS,
    },
    hillCountrySilvopasturePlanting: {
      geometryType: 'Polygon',
      label: 'Hill country - silvopasture',
      categoryKey: 'planting',
      borderColor: '#7dbe68',
      renderContent: (props) => FeatureForm({ featureType: 'hillCountrySilvopasturePlanting', ...props }),
      zIndex: 20,
      ...DEFAULT_POLYGON_OPTIONS,
    },
    sitePreparation: {
      geometryType: 'Polygon',
      label: 'Preparation blanket spray',
      categoryKey: 'sitePreparation',
      borderColor: '#f56720',
      renderContent: (props) => FeatureForm({ featureType: 'sitePreparation', ...props }),
      zIndex: 10,
      ...DEFAULT_POLYGON_OPTIONS,
    },
    sitePreparationSpotSpraying: {
      geometryType: 'Polygon',
      label: 'Preparation spot spray',
      categoryKey: 'sitePreparation',
      borderColor: '#F87700',
      renderContent: (props) => FeatureForm({ featureType: 'sitePreparationSpotSpraying', ...props }),
      zIndex: 10,
      ...DEFAULT_POLYGON_OPTIONS,
    },
    maintenanceSpraying: {
      geometryType: 'Polygon',
      label: 'Maintenance blanket spray',
      categoryKey: 'siteMaintenance',
      borderColor: '#FFA800',
      renderContent: (props) => FeatureForm({ featureType: 'maintenanceSpraying', ...props }),
      zIndex: 10,
      ...DEFAULT_POLYGON_OPTIONS,
    },
    maintenanceSpotSpraying: {
      geometryType: 'Polygon',
      label: 'Maintenance spot spray',
      categoryKey: 'siteMaintenance',
      borderColor: '#ffbf00',
      renderContent: (props) => FeatureForm({ featureType: 'maintenanceSpotSpraying', ...props }),
      zIndex: 10,
      ...DEFAULT_POLYGON_OPTIONS,
    },
    fundedFencing: {
      geometryType: 'LineString',
      label: 'Funded fence',
      categoryKey: 'fencing',
      color: '#FF0F00',
      weight: FENCING_LINE_WEIGHT,
      renderContent: (props) => FeatureForm({ featureType: 'fundedFencing', ...props }),
      zIndex: 30,
    },
    existingFencing: {
      geometryType: 'LineString',
      label: 'Existing fence',
      categoryKey: 'fencing',
      color: '#7805A0',
      weight: FENCING_LINE_WEIGHT,
      zIndex: 30,
    },
  },
  permissions: {
    editMode: [],
  },
}

export interface FeatureCategory {
  name: string
  value: string
}

// TODO: wire up actual feature categories and make worky...
export const FEATURE_CATEGORIES: Record<string, string> = {
  propertyBoundary: 'Property boundary',
  planting: 'Planting',
  gateTrough: 'Gates & troughs',
  siteMaintenance: 'Site maintenance',
  sitePreparation: 'Site preparation',
  fencing: 'Fencing',
}
