import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTable, useSortBy, usePagination, SortingRule, Cell } from 'react-table'
import { Button } from '../button'
import { InlineNotification } from '../inline-notification'
import { Tag } from '../tag'
import { SortIcon, SortState } from './sort-icon'
import { TableFooter } from './table-footer'
import { PaginationProps } from './table-footer/pagination'
import { TableSkeleton } from './table-skeleton'
import { useNavigate } from '@msaf/router-react'
import { DISPLAY_DATE_FORMAT, formatDate } from '@msaf/core-common'
import { ActionsMenu } from '../../navigation/actions-menu'
import { DropdownMenu, MenuItem } from '../../navigation'

export type TableActionArgs = { constant: string } | { elementKey: string }

export type TableActionType = 'transitionAction' | 'deleteAction'

export type TableAction = {
  label: string
  type: TableActionType
  actionPermissions?: Array<string>
  modifier?: (cell: any, self: TableAction) => TableAction
  args?: Array<TableActionArgs>
}

export type ColumnHeader = {
  elementKey: string
  columnHeading: string
  viewColumn: string
  sortable?: boolean
  type: 'hidden' | 'actions-no-data' | 'actions-menu' | 'text' | 'tag' | 'date'
  actions?: Array<TableAction>
  fieldFormatter?: (v: string) => string
}

export type TableSortByProps = {
  orderColumn: string
  orderDirection?: 'asc' | 'desc'
}

export interface TableProps extends PaginationProps {
  className?: string
  columnHeaders: Array<ColumnHeader>
  tableData: Record<string, string | number | boolean>[]
  onRowClick?: Omit<TableAction, 'modifier' | 'actionPermissions'>
  customActions?: { [key: string]: (...args: any[]) => void }
  defaultSortBy?: TableSortByProps
  setSortColumn: (orderColumn: string, orderDirection: 'asc' | 'desc') => void
  currentUserPermissions?: Array<string>
  pageSize?: number
  noResultsMessage?: string
  isSkeleton?: boolean
  dateFormat?: string
  // Should match up with `$vertical-spacing--<size>` variables in client/src/styles/0-settings/_sizes.scss:
  verticalSpacing?: 'none' | 'small' | 'x-small'
}

export function Table({
  className,
  columnHeaders,
  tableData,
  onRowClick,
  customActions,
  setSortColumn,
  defaultSortBy,
  currentUserPermissions,
  currentPage,
  maxPageLinksVisible,
  setPage,
  totalResults,
  totalPages,
  showResultsCount,
  resultNameSingular = 'result',
  resultNamePlural = 'results',
  pageSize = 10,
  noResultsMessage,
  isSkeleton,
  dateFormat = DISPLAY_DATE_FORMAT,
  verticalSpacing,
}: TableProps) {
  const navigate = useNavigate()
  const [visibleColumns, setVisibleColumns] = useState<Array<ColumnHeader>>([])
  const [visibleActions, setVisibleActions] = useState<Array<TableAction>>([])

  const handleTableAction = (e: React.MouseEvent<any, MouseEvent>, cell: Cell, action: TableAction) => {
    e.stopPropagation()
    const args: Array<string> = []
    action.args?.forEach((arg: TableActionArgs) => {
      if ('constant' in arg && arg.constant !== undefined) {
        args.push(arg.constant)
      }
      if ('elementKey' in arg && arg.elementKey !== undefined && cell?.row?.values) {
        args.push(cell.row.values[arg.elementKey])
      }
    })

    const type = action.type

    if ('transitionAction' === type) {
      navigate(`/${args.join('/')}`)
    } else if (customActions && customActions[type]) {
      customActions[type](...args)
    } else {
      const error = `attempted to run action.${type}(` + [...args].join(' ') + ')'
      throw new Error(error)
    }
  }

  const handleRowClick = (e: any, cell: Cell) => {
    if (onRowClick) {
      handleTableAction(e, cell, onRowClick)
    }
  }

  const getDefaultSortBy = (defaultSortBy?: TableSortByProps) => {
    return defaultSortBy
      ? [
          {
            id: defaultSortBy.orderColumn,
            desc: defaultSortBy.orderDirection === 'desc' ? true : false,
          },
        ]
      : []
  }

  const modifyActions = useCallback(
    (cell: Cell) => {
      return visibleActions.map((action) => {
        return !action.modifier ? action : action.modifier(cell, action)
      })
    },
    [visibleActions],
  )

  useEffect(() => {
    const getActionsWithPermissions = (actions: Array<TableAction>) => {
      return actions.filter(({ actionPermissions }) => {
        // Check action permission against current user permissions, if present
        // Otherwise don't return actions
        if (!actionPermissions) {
          return true
        } else if (currentUserPermissions) {
          return currentUserPermissions?.some((perm) => actionPermissions.includes(perm))
        } else {
          return false
        }
      })
    }

    const filteredColumns: Array<ColumnHeader> = columnHeaders?.filter((col: ColumnHeader) => {
      if (['actions-no-data', 'actions-menu'].includes(col.type) && Array.isArray(col.actions)) {
        const actions = getActionsWithPermissions(col.actions)
        setVisibleActions(actions)
        return !!actions?.length
      }
      return true
    })
    setVisibleColumns(filteredColumns)
  }, [columnHeaders, currentUserPermissions])

  const useTableDefaults = {
    columns: (visibleColumns ?? [])?.map((col: ColumnHeader) => {
      return {
        Header: col.columnHeading,
        accessor: col.viewColumn ?? col.elementKey,
        disableSortBy: !col.sortable,
        sortDescFirst: true,
        type: col.type,
        Cell: (cell: Cell) => {
          switch (col.type) {
            case 'actions-no-data':
              const actions = modifyActions(cell)
              return (
                Array.isArray(actions) &&
                actions.map((action: any) => (
                  <Button
                    type='button'
                    label={action.label}
                    key={action.label}
                    onClick={(e) => handleTableAction(e, cell, action)}
                    buttonStyle='text-action'
                    className='c-table__action'
                  />
                ))
              )
            case 'actions-menu':
              const menuActions = modifyActions(cell)
              return (
                Array.isArray(menuActions) && (
                  <ActionsMenu label={'Actions'} buttonStyle='secondary' isSkeleton={isSkeleton} preventClickBubbling>
                    <DropdownMenu>
                      {menuActions.map((action: any) => (
                        <MenuItem
                          key={action.label}
                          label={action.label}
                          action={(e) => handleTableAction(e, cell, action)}
                        />
                      ))}
                    </DropdownMenu>
                  </ActionsMenu>
                )
              )
            case 'date':
              return formatDate(cell.value, dateFormat)
            case 'tag':
              // TODO: add modifier class for Tag to remove margin bottom, remove nested style from table scss
              return <Tag label={col.fieldFormatter?.(cell.value) ?? cell.value} />
            default:
              return col.fieldFormatter?.(cell.value) ?? cell.value
          }
        },
      }
    }),
    data: tableData,
    manualPagination: true,
    manualSortBy: true,
    disableMultiSort: true,
    pageCount: 1,
    autoResetPage: false,
    autoResetHiddenColumns: false,
    autoResetSortBy: false,
    disableSortRemove: true,
    initialState: {
      pageIndex: 0,
      // Results to show per page
      pageSize: pageSize,
      sortBy: useMemo(() => getDefaultSortBy(defaultSortBy), [defaultSortBy]),
    },
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { sortBy },
    setHiddenColumns,
  } = useTable(useTableDefaults as any, useSortBy, usePagination)

  useEffect(() => {
    if (columnHeaders) {
      setHiddenColumns(() =>
        (columnHeaders as Array<ColumnHeader>).filter((col) => col.type === 'hidden').map((col) => col.elementKey),
      )
    }
  }, [columnHeaders, setHiddenColumns])

  useEffect(() => {
    const [sortData]: SortingRule<object>[] = sortBy
    sortData && setSortColumn(sortData.id, sortData.desc === true ? 'desc' : 'asc')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy])

  const containerClasses = classNames('c-table', className, {
    [`c-table--vert-spacing-${verticalSpacing}`]: !!verticalSpacing,
  })

  if (isSkeleton) {
    return (
      <div className='c-table'>
        <div className='c-table__container'>
          <TableSkeleton numRows={pageSize} numCols={columnHeaders?.length ?? undefined} />
        </div>
        <TableFooter
          isSkeleton
          currentPage={currentPage}
          setPage={setPage}
          totalResults={totalResults}
          totalPages={totalPages}
          maxPageLinksVisible={maxPageLinksVisible}
          resultNameSingular={resultNameSingular}
          resultNamePlural={resultNamePlural}
          showResultsCount={showResultsCount}
        />
      </div>
    )
  }

  return totalResults < 1 ? (
    <InlineNotification isDismissable={false}>
      {!!noResultsMessage ? noResultsMessage : `No ${resultNamePlural} found.`}
    </InlineNotification>
  ) : (
    <div className={containerClasses}>
      <div className='c-table__container'>
        <table className='c-table__table-element' {...getTableProps()}>
          <thead className='c-table__head'>
            {headerGroups.map((headerGroup) => (
              <tr className='c-table__row c-table__row--header' {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((header: any) => {
                  const headerClasses = classNames('c-table__cell', 'c-table__cell--header', {
                    'c-table__cell--active-header': header.isSorted,
                    'c-table__cell--actions': ['actions-no-data', 'actions-menu'].includes(header.type),
                  })

                  let sortState = SortState.inactive
                  if (header.isSortedDesc !== undefined) {
                    sortState = header.isSortedDesc ? SortState.desc : SortState.asc
                  }

                  return (
                    <th key={header.id} className={headerClasses} scope='col'>
                      {/* If column sortable, make label button element for keyboard nav/a11y etc */}
                      {header.disableSortBy ? (
                        <span
                          className='c-table__header-label'
                          {...header.getHeaderProps(header.getSortByToggleProps())}
                        >
                          <span className='c-table__label-text'>{header.render('Header')}</span>
                        </span>
                      ) : (
                        <button
                          className='c-table__header-label'
                          type='button'
                          {...header.getHeaderProps(header.getSortByToggleProps())}
                        >
                          <span className='c-table__label-text'>{header.render('Header')}</span>
                          <SortIcon sortState={sortState} />
                        </button>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody className='c-table__body' {...getTableBodyProps()}>
            {page.map((row: any) => {
              prepareRow(row)

              return (
                <tr key={row.id} className='c-table__row c-table__row--content' {...row.getRowProps()}>
                  {row.cells.map((cell: any, i: number) => {
                    const cellClasses = classNames('c-table__cell', 'c-table__cell--content', {
                      'c-table__cell--actions': ['actions-no-data', 'actions-menu'].includes(cell.column.type),
                    })

                    return (
                      <td
                        key={cell.value}
                        className={cellClasses}
                        {...cell.getCellProps()}
                        onClick={(e) => handleRowClick(e, cell)}
                      >
                        {/* Make first item in row a button to allow for keyboard navigation (CSS used to display normally/handle row focus state)  */}
                        {i === 0 && !!onRowClick ? (
                          <button type='button' className='c-table__row-click-btn'>
                            {cell.render('Cell')}
                          </button>
                        ) : (
                          cell.render('Cell')
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {/* Pagination, result count and (conditional) 'Go to page' controls: */}
      <TableFooter
        currentPage={currentPage}
        setPage={setPage}
        totalResults={totalResults}
        totalPages={totalPages}
        maxPageLinksVisible={maxPageLinksVisible}
        resultNameSingular={resultNameSingular}
        resultNamePlural={resultNamePlural}
        showResultsCount={showResultsCount}
      />
    </div>
  )
}
