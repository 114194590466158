import { useMutation } from 'react-query'
import { useRequest } from './useRequest'
import { ToastMessage, createToastMessage } from '@msaf/core-react'
import { Method } from 'axios'

export const useMutationWithToast = <TData, TError, TVariables, TContext>({
  method,
  url,
  success,
  error,
}: {
  method: Method
  url: string
  success: {
    action?: CallableFunction
    toastMessage: string
  }
  error: {
    action?: CallableFunction
    toastMessage: string
  }
}) => {
  const { client } = useRequest()
  return useMutation<TData, TError, TVariables, TContext>((data) => client.request({ method, url, data }), {
    onSuccess: () => {
      createToastMessage(<ToastMessage messageType='success' title='Success' message={success.toastMessage} />)
      success.action?.()
    },
    onError: () => {
      createToastMessage(<ToastMessage messageType='error' title='Error' message={error.toastMessage} />)
      error.action?.()
    },
  })
}
