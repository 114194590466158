import { PropsWithChildren } from 'react'
import { BaseApplicationWorkflow } from '.'
import {
  EDIT_APPLICATION_PROGRESS_PERMISSIONS as EDIT_APPLICATION_PROGRESS_PERMISSIONS,
  UserPermissions,
} from '../../types/permissions'
import { useViewRedirectGatekeeper } from '../gatekeeper'

interface WorkflowProps {
  recordId: string
}

export const ProgressWorkflow = ({ children, recordId }: PropsWithChildren<WorkflowProps>) => {
  useViewRedirectGatekeeper(
    [...EDIT_APPLICATION_PROGRESS_PERMISSIONS, UserPermissions.ASSIGN_PROGRESS],
    UserPermissions.VIEW_PROGRESS,
  )

  return (
    <BaseApplicationWorkflow recordId={recordId} workflow={'progress'} noResultsText={'Not contracted'}>
      {children}
    </BaseApplicationWorkflow>
  )
}
