import React from 'react'
import { AuthStrategy, RequestOptions, SearchQuery, TokenAuthOptions } from '@msaf/generic-search-common'
import { CookieAuthDownload } from './cookie-auth-token'
import { TokenAuthDownload } from './token-auth-download'

export interface ExportSearchResultsProps<T extends AuthStrategy = 'token'> {
  actionUrl: string
  actionQuery?: SearchQuery
  actionLabel: string
  requestOptions?: RequestOptions<T>
  csrfToken?: string
}

export function ExportSearchResults<T extends AuthStrategy = 'token'>(props: ExportSearchResultsProps<T>) {
  if (props.requestOptions?.authStrategy === 'token') {
    return <TokenAuthDownload {...props} requestOptions={props.requestOptions as TokenAuthOptions} />
  } else {
    return <CookieAuthDownload {...props} />
  }
}
