import { ColumnHeader, createToastMessage, ToastMessage } from '@msaf/core-react'
import { AxiosError } from 'axios'
import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../../../auth'
import { usePlanState } from '../../../../../../contexts/plan-context'
import { FormElementConfig } from '../../../../../../forms/types'
import { toDollarCurrencyFormat } from '../../../../../../forms/utils'
import { usePostAction } from '../../../../../../hooks/use-post-action'
import { useTableState } from '../../../../../../hooks/use-table-state'
import { useRequest } from '../../../../../../hooks/useRequest'
import { AppNoteType, NoteForm } from '../../../../../../types/notes'
import { UserPermissions } from '../../../../../../types/permissions'
import { RouteMode } from '../../../../../../types/route'
import { ApplicationStatusFields } from '../../application/[applicationId]/submission'
import { PlanOverviewResources } from './plan-overview-resources'
import { formatDisplayDate } from '../../../../../../utils/dates'

export interface OverviewFields {
  actualVisitDate: string | null
  planPriorities: Array<{ value: string | null }>
  regulatoryConsiderations: string | null
  planOverviewResources: Array<string>
  notes: Array<AppNoteType>
}

export interface ApplicationData {
  id: number
  applicationNo: string
  identifier: string
}

export const useOverviewForm = (
  id: string | undefined,
  data: OverviewFields | undefined,
  mode: RouteMode | undefined,
  isMigrated: boolean | undefined,
): FormElementConfig<OverviewFields>[] => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null)
  const { showModal, refreshTable, setShowModal, deleteAction, postDeleteAction } = useTableState()
  const { refetchState: refetchApplicationWorkflowState } = usePlanState()
  const { client } = useRequest()

  // Checks the application status to determine if it should be allowed to be deleted.
  // User should not be able to delete the first application of a migrated record.
  useQuery<ApplicationStatusFields>(
    [id, selectedRowId],
    () => client.get(`/api/plan/${id}/project/${selectedRowId}/status`).then((response) => response.data),
    {
      enabled: selectedRowId !== null,
      onSuccess: (data) => {
        if (selectedRowId && !data.isMigrated) {
          deleteAction(selectedRowId)
        } else {
          setSelectedRowId(null)
          createToastMessage(
            <ToastMessage
              messageType='error'
              title='Error'
              message='Sorry, the first project of a migrated plan cannot be deleted.'
            />,
          )
        }
      },
    },
  )

  const createApplication = usePostAction<
    Partial<ApplicationData>,
    Partial<ApplicationData>,
    AxiosError<{ message: string | undefined }>
  >({
    url: `/api/plan/${id}/project/`,
    displayServerError: true,
    messages: {
      success: 'Project created.',
      error: 'Could not create project.',
    },
  })

  const postApplicationDeleteAction = () => {
    postDeleteAction()
    refetchApplicationWorkflowState?.()
  }

  return useMemo(
    () => [
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Priorities and work phases' },
      },
      {
        type: 'field',
        element: {
          fieldId: 'actualVisitDate',
          type: 'date',
          isRequired: !isMigrated,
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'planPriorities',
          type: 'text',
          isRequired: !isMigrated,
          repeating: true,
          helpText:
            'The priorities of the sediment reduction plan should be based on the identified \
            risks, opportunities, and current management practices.',
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'regulatoryConsiderations',
          type: 'text-area',
          isRequired: !isMigrated,
          helpText: 'Consider the Ministry for the Environment slope layer and other regulatory context below',
        },
      },
      {
        type: 'atom',
        element: {
          fieldId: 'planOverviewResources',
          type: 'inline-notification',
          isDismissable: false,
          messageType: 'info',
          content: <PlanOverviewResources />,
        },
      },
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Funding projects' },
      },
      {
        type: 'atom',
        element: {
          type: 'plan-warnings',
        },
      },
      {
        type: 'modal',
        element: {
          isOpen: showModal,
          type: 'confirm-action',
          heading: 'Delete project',
          body: 'Are you sure you want to delete this project?',
          contentLabel: 'Project delete confirmation dialog',
          successMessage: 'Project deleted',
          errorMessage: 'Could not delete project. Please try again.',
          primaryActionLabel: 'Delete',
          requestUrl: `/api/plan/${id}/project/${selectedRowId}`,
          postActionFunc: postApplicationDeleteAction,
          onRequestClose: () => setShowModal(false),
        },
      },
      {
        type: 'field',
        element: {
          type: 'table',
          currentUserPermissions: user?.permissions,
          columnHeaders: [
            {
              elementKey: 'id',
              columnHeading: 'Id',
              viewColumn: 'id',
              type: 'hidden',
            },
            {
              elementKey: 'applicationNo',
              columnHeading: 'Project number',
              viewColumn: 'applicationNo',
              sortable: true,
              type: 'text',
            },
            {
              elementKey: 'startYear',
              columnHeading: 'Anticipated start of works',
              viewColumn: 'startYear',
              sortable: true,
              type: 'text',
              fieldFormatter: (date) => formatDisplayDate(date),
            },
            {
              elementKey: 'totalCostOfWorks',
              columnHeading: 'Total cost of works',
              viewColumn: 'totalProposedCost',
              sortable: true,
              type: 'text',
              fieldFormatter: toDollarCurrencyFormat,
            },
            {
              elementKey: 'actions',
              columnHeading: '',
              viewColumn: 'actions',
              type: 'actions-no-data',
              actions:
                mode == RouteMode.EDIT
                  ? [
                      {
                        label: 'Delete',
                        type: 'deleteAction',
                        args: [
                          {
                            elementKey: 'id',
                          },
                        ],
                        actionPermissions: [UserPermissions.EDIT_PLANS, UserPermissions.EDIT_PLANS_ASSIGNED],
                      },
                    ]
                  : [],
            },
          ] as ColumnHeader[],
          refreshTable: refreshTable,
          tableActionHandlers: {
            deleteAction: (rowId: number) => setSelectedRowId(rowId),
          },
          defaultSortBy: {
            orderColumn: 'id',
          },
          requestUrl: `/api/plan/${id}/project/`,
          rowClickAction: {
            label: 'View',
            type: 'transitionAction',
            args: [{ constant: `plans/${mode}/${id}/plan/project` }, { elementKey: 'id' }, { constant: 'details' }],
          },
          noResultsMessage: 'No projects added',
        },
      },
      {
        type: 'action',
        element: {
          buttonStyle: 'primary',
          type: 'button',
          icon: 'add',
          iconAlignment: 'left',
          label: 'New project',
          onClick: () => {
            createApplication.mutate(
              {},
              {
                onSuccess: ({ data }) => {
                  refetchApplicationWorkflowState?.()
                  navigate(`/plans/${mode}/${id}/plan/project/${data.id}/details`)
                },
              },
            )
          },
        },
      },
      {
        type: 'field',
        element: {
          type: 'notes',
          fieldId: 'notes',
          form: NoteForm.OVERVIEW,
        },
      },
    ],
    [data, id, showModal],
  ) as FormElementConfig<OverviewFields>[]
}
