import { ClientCache } from '@msaf/core-common'
import { PageProvider, ToastContainer } from '@msaf/core-react'
import { useEffect } from 'react'
import ReactModal from 'react-modal'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { AuthProvider } from './app/auth'
import { EoiPage } from './app/components/eoi-page'
import { ErrorBoundary } from './app/components/error-boundary'
import NotFound from './app/components/not-found'
import Page from './app/components/page'
import { PlanPage } from './app/components/plan-page'
import { ProtectedRoute } from './app/components/protect-route'
import { APPLICATION_MAP_PATH, PLAN_MAP_PATH } from './app/constants'
import { AppContextProvider } from './app/contexts/app-context'
import RaygunClient from './app/logging/raygun'
import { Administration } from './app/routes/admin'
import AdminUsers from './app/routes/admin/users'
import { User } from './app/routes/admin/users/[mode]/[id]/user'
import { NewUser } from './app/routes/admin/users/new'
import { Dashboard } from './app/routes/dashboard'
import { DirectImage } from './app/routes/direct-image'
import { Plans } from './app/routes/plans'
import { Action } from './app/routes/plans/[mode]/[id]/application/[applicationId]/action/[actionId]'
import { Application } from './app/routes/plans/[mode]/[id]/application/[applicationId]/application'
import { ApplicationDetails } from './app/routes/plans/[mode]/[id]/application/[applicationId]/details'
import { ApplicationPanelistReview } from './app/routes/plans/[mode]/[id]/application/[applicationId]/panelist-review'
import { Payments } from './app/routes/plans/[mode]/[id]/application/[applicationId]/payments'
import { PaymentsSummary } from './app/routes/plans/[mode]/[id]/application/[applicationId]/payments/payments-summary'
import { RequestForPayment } from './app/routes/plans/[mode]/[id]/application/[applicationId]/payments/request-for-payment'
import { ApplicationApproval } from './app/routes/plans/[mode]/[id]/application/[applicationId]/review/approval'
import { ApplicationReview } from './app/routes/plans/[mode]/[id]/application/[applicationId]/review/review'
import { Progress } from './app/routes/plans/[mode]/[id]/application/[applicationId]/progress'
import { ActionVerification } from './app/routes/plans/[mode]/[id]/application/[applicationId]/progress/action-verification'
import { ActionVerificationMap } from './app/routes/plans/[mode]/[id]/application/[applicationId]/progress/action-verification/map'
import { SignOff } from './app/routes/plans/[mode]/[id]/application/[applicationId]/progress/sign-off'
import { ApplicationSubmission } from './app/routes/plans/[mode]/[id]/application/[applicationId]/submission'
import { Supply } from './app/routes/plans/[mode]/[id]/application/[applicationId]/supply'
import { Contract } from './app/routes/plans/[mode]/[id]/application/[applicationId]/supply/contract'
import { Nursery } from './app/routes/plans/[mode]/[id]/application/[applicationId]/supply/nursery'
import { TransferAction } from './app/routes/plans/[mode]/[id]/application/[applicationId]/supply/nursery/transfer-action'
import { EoiDecision } from './app/routes/plans/[mode]/[id]/decision'
import { PlanEoi } from './app/routes/plans/[mode]/[id]/eoi'
import { PlanExportView } from './app/routes/plans/[mode]/[id]/export'
import { PlanHistory } from './app/routes/plans/[mode]/[id]/history'
import { HistoryAtom } from './app/routes/plans/[mode]/[id]/history/[atom-id]'
import { PlanMap } from './app/routes/plans/[mode]/[id]/map'
import { PaymentsWrapper } from './app/routes/plans/[mode]/[id]/payments-wrapper'
import { Plan } from './app/routes/plans/[mode]/[id]/plan'
import { Applicant } from './app/routes/plans/[mode]/[id]/plan/applicant'
import { CatchmentContext } from './app/routes/plans/[mode]/[id]/plan/catchment-context'
import { PlanOverview } from './app/routes/plans/[mode]/[id]/plan/overview'
import { PreChecks as PlanPreChecks } from './app/routes/plans/[mode]/[id]/plan/pre-checks'
import { PropertySystemsAspirations } from './app/routes/plans/[mode]/[id]/plan/property-systems-aspirations'
import { SystemAspirations } from './app/routes/plans/[mode]/[id]/plan/system-aspirations'
import { PlanReview } from './app/routes/plans/[mode]/[id]/review'
import { ReviewSectionWrapper } from './app/routes/plans/[mode]/[id]/review-wrapper'
import { ProgressWrapper } from './app/routes/plans/[mode]/[id]/progress-wrapper'
import { SupplySectionWrapper } from './app/routes/plans/[mode]/[id]/supply-wrapper'
import { PlanMapSearch } from './app/routes/plans/map'
import { NewPlanEoi } from './app/routes/plans/new'
import { Redirector } from './app/components/redirector'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
    },
  },
})

RaygunClient.getInstance().init()
ReactModal.setAppElement('#root')

function App() {
  useEffect(() => {
    // Initialise the app cache
    // `ClientCache` can be used to persist values across routes
    ClientCache.getInstance()
    // Clear cache on unmount
    return () => ClientCache.getInstance().flush()
  }, [])

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <BrowserRouter>
            <AppContextProvider>
              <Routes>
                <Route element={<ProtectedRoute />}>
                  <Route path='/direct-image/:uuid' element={<DirectImage />} />
                  <Route path='/plans/map' element={<PlanMapSearch />} />
                  <Route
                    path={PLAN_MAP_PATH}
                    element={
                      <PageProvider>
                        <PlanMap />
                      </PageProvider>
                    }
                  />
                  <Route
                    path={APPLICATION_MAP_PATH}
                    element={
                      <PageProvider>
                        <ActionVerificationMap />
                      </PageProvider>
                    }
                  />
                  <Route path='/plans/:mode/:id/plan/project/:applicationId/export/view' element={<PlanExportView />} />
                  <Route element={<Page />}>
                    <Route path='/' element={<Dashboard />} />
                    <Route path='/plans'>
                      <Route index element={<Plans />} />
                      <Route path='new' element={<NewPlanEoi />} />
                      <Route path=':mode/:id' element={<PlanPage />}>
                        <Route path='eoi' element={<EoiPage />}>
                          <Route path='registration' element={<PlanEoi />} />
                          <Route path='decision' element={<EoiDecision />} />
                          <Route index element={<Navigate to='registration' />} />
                        </Route>
                        <Route path='plan' element={<Plan />}>
                          <Route path='pre-checks' element={<PlanPreChecks />} />
                          <Route path='applicant' element={<Applicant />} />
                          <Route path='property-systems-aspirations' element={<PropertySystemsAspirations />} />
                          <Route path='catchment-context' element={<CatchmentContext />} />
                          <Route path='system-aspirations' element={<SystemAspirations />} />
                          <Route path='overview' element={<PlanOverview />} />
                          <Route path='project/:applicationId' element={<Application />}>
                            <Route path='details' element={<ApplicationDetails />} />
                            <Route path='submission' element={<ApplicationSubmission />} />
                            <Route path='action/:actionId' element={<Action />} />
                          </Route>
                          <Route index element={<Navigate to='pre-checks' />} />
                        </Route>
                        <Route path='review' element={<PlanReview />}>
                          <Route path='project/:applicationId' element={<ReviewSectionWrapper />}>
                            <Route path='review' element={<ApplicationReview />} />
                            <Route path='approval' element={<ApplicationApproval />} />
                            <Route path='review/panelist-review/:reviewId' element={<ApplicationPanelistReview />} />
                          </Route>
                        </Route>
                        <Route path='supply' element={<Supply />}>
                          <Route path='project/:applicationId' element={<SupplySectionWrapper />}>
                            <Route path='nursery'>
                              <Route path=':actionId/transfer' element={<TransferAction />} />
                              <Route index element={<Nursery />} />
                            </Route>
                            <Route path='contract' element={<Contract />} />
                          </Route>
                        </Route>
                        {/* Add redirects for previously supported routes */}
                        <Route path='sign-off'>
                          <Route path='' element={<Redirector target='/plans/:mode/:id/progress' />} />
                          <Route path='project' element={<Redirector target='/plans/:mode/:id/progress' />} />
                          <Route path='project/:applicationId'>
                            <Route
                              path=''
                              element={<Redirector target='/plans/:mode/:id/progress/project/:applicationId' />}
                            />
                            <Route
                              path='pre-checks'
                              element={
                                <Redirector target='/plans/:mode/:id/progress/project/:applicationId/sign-off' />
                              }
                            />
                            <Route
                              path='progress'
                              element={
                                <Redirector target='/plans/:mode/:id/progress/project/:applicationId/sign-off' />
                              }
                            />
                            <Route
                              path='assessment'
                              element={
                                <Redirector target='/plans/:mode/:id/progress/project/:applicationId/sign-off' />
                              }
                            />
                          </Route>
                        </Route>
                        <Route path='progress' element={<Progress />}>
                          <Route path='project/:applicationId' element={<ProgressWrapper />}>
                            <Route path='sign-off' element={<SignOff />} />
                            <Route path='action-verification/:actionId' element={<ActionVerification />} />
                          </Route>
                        </Route>
                        <Route path='payments' element={<Payments />}>
                          <Route path='project/:applicationId' element={<PaymentsWrapper />}>
                            <Route path='payments'>
                              <Route index element={<PaymentsSummary />} />
                              <Route path=':paymentId' element={<RequestForPayment />} />
                            </Route>
                          </Route>
                        </Route>
                        <Route path='history'>
                          <Route path=':atomId/details' element={<HistoryAtom />} />
                          <Route index element={<PlanHistory />} />
                        </Route>

                        <Route index element={<Navigate to='plan' />} />
                      </Route>
                    </Route>
                    <Route path='/admin' element={<Administration />}>
                      <Route path='users'>
                        <Route index element={<AdminUsers />} />
                        <Route path='new' element={<NewUser />} />
                        <Route path=':mode/:id'>
                          <Route path='user' element={<User />} />
                        </Route>
                      </Route>
                      <Route index element={<Navigate to='users' />} />
                    </Route>
                  </Route>
                  <Route path='*' element={<NotFound />} />
                </Route>
              </Routes>
            </AppContextProvider>
          </BrowserRouter>
          {/* Container for toast messages (needs to be rendered once in application tree) */}
          {/* Close after 10s */}
          <ToastContainer autoClose={10000} />
        </AuthProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  )
}

export default App
