import { RecordContainer, WorkflowNav } from '@msaf/core-react'
import { useQuery } from 'react-query'
import { Outlet, useParams } from 'react-router-dom'
import { useViewRedirectGatekeeper } from '../../../../components/gatekeeper'
import { ApplicationsState, usePlanWorkflowLinks } from '../../../../config/workflow-nav'
import PlanProvider from '../../../../contexts/plan-context'
import { usePlanSummary } from '../../../../hooks/usePlanSummary'
import { useRequest } from '../../../../hooks/useRequest'
import { usePermission } from '../../../../services/permissions'
import { EDIT_PLAN_PERMISSIONS, UserPermissions } from '../../../../types/permissions'
import { RouteParams } from '../../../../types/route'
import { useWorkflowState } from '../../../../validation/hooks'

export const Plan = () => {
  const { id, mode } = useParams<RouteParams>()

  const permissions_url = `/api/plan/${id}/permissions`
  const { data: permissions } = useQuery<{ canEdit: boolean }>([permissions_url], () =>
    client.get(permissions_url).then((response) => response.data),
  )

  // Edit permissions are checked against the server to perform an object level permissions check.
  // This allows field advisors the ability to view plans they are not assigned.
  const editPermissions = !!permissions?.canEdit ? EDIT_PLAN_PERMISSIONS : []
  useViewRedirectGatekeeper(editPermissions, UserPermissions.VIEW_PLANS)

  const hasEditPermission = usePermission(editPermissions)
  const { isMigratedPlan } = usePlanSummary()

  const { client } = useRequest()
  const project_url = `/api/plan/${id}/project/`
  const { data: applications, refetch: refreshApplications } = useQuery<Array<ApplicationsState>>([project_url], () =>
    client.get(project_url).then((response) => response.data),
  )

  const { data: planWorkflowState, refetch: refetchPlanWorkflowState } = useWorkflowState(id, 'plan', mode)

  const workflowNavLinks = usePlanWorkflowLinks(id, mode, planWorkflowState, applications)

  return (
    <PlanProvider
      planState={{
        isMigratedPlan,
        hasEditPermission,
        refetchState: () => {
          refreshApplications()
          refetchPlanWorkflowState()
        },
      }}
    >
      <RecordContainer workflowNav={<WorkflowNav sections={workflowNavLinks} />}>
        <Outlet />
      </RecordContainer>
    </PlanProvider>
  )
}
