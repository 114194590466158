import { createToastMessage, MenuItem, ToastMessage } from '@msaf/core-react'
import { TokenAuthOptions } from '@msaf/generic-search-common'
import React, { useEffect } from 'react'
import { ExportSearchResultsProps } from '..'
import { useSearchResultExport } from '../../../hooks/use-api'

export function TokenAuthDownload({
  actionUrl,
  actionQuery,
  actionLabel,
  requestOptions,
}: Omit<ExportSearchResultsProps, 'csrfToken'>) {
  const { data, isLoading, isError, mutate, reset } = useSearchResultExport(actionQuery, {
    ...requestOptions,
    urlPrefix: `${requestOptions?.urlPrefix}`,
  } as TokenAuthOptions)

  useEffect(() => {
    if (data?.token) {
      const url = `${actionUrl}/${data.token}`
      const fakeAnchorElement = document.createElement('a')
      fakeAnchorElement.id = 'download-csv'
      fakeAnchorElement.style.display = 'none'
      fakeAnchorElement.href = url
      fakeAnchorElement.setAttribute('href', url)
      fakeAnchorElement.rel = 'noopener noreferrer'
      fakeAnchorElement.target = 'blank'
      fakeAnchorElement.click()

      setTimeout(() => fakeAnchorElement.remove(), 1000)

      reset()
    }
  }, [data?.token])

  if (!isLoading && isError) {
    createToastMessage(
      <ToastMessage messageType='error' title='Error' message='Could not download the CSV. Please try again.' />,
    )
  }

  return <MenuItem label={actionLabel} action={() => mutate()} className='c-dropdown-menu__link' />
}
