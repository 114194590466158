import { AUTOCOMPLETE_MIN_CHARS, ReadOnlyField, Typeahead, TypeaheadOptionsProps } from '@msaf/core-react'
import debounce from 'lodash.debounce'
import { useMemo } from 'react'
import { OptionsType, OptionTypeBase } from 'react-select'
import { useRequest } from '../hooks/useRequest'
import { LookupItem } from '../types'

interface LookupAddressProps extends Partial<Omit<TypeaheadOptionsProps, 'value' | 'setValue'>> {
  labelledBy: string
  address: string
  setAddressFields: (addressFields: string) => void
  autoCompleteMinChars?: number
}

interface LocationData {
  Text: string
}

export default function LookupAddress({
  isSkeleton = false,
  labelledBy,
  address,
  setAddressFields,
  isDisabled = false,
  autoFocus = false,
  autoCompleteMinChars = AUTOCOMPLETE_MIN_CHARS,
  menuPortalTarget,
  ...props
}: LookupAddressProps) {
  // TODO: Extract the data fetch so this component can be generic
  // Fetch data
  const { client } = useRequest()

  const getAddressSuggestions = useMemo(
    () =>
      debounce(async (query: string, callback: (options: OptionsType<OptionTypeBase>) => void) => {
        const { data } = await client.get<LocationData[]>(
          `/api/lookup?field=address&query=${encodeURIComponent(query)}`,
        )
        callback(data.map((address) => ({ label: address.Text, value: address.Text })))
      }, 300),
    [],
  )

  const loadOptions = (inputValue: string, callback: (options: OptionsType<OptionTypeBase>) => void) => {
    if (inputValue == null || inputValue === '' || inputValue.length < autoCompleteMinChars) return callback([])
    if (inputValue.length >= autoCompleteMinChars) {
      getAddressSuggestions(inputValue, callback)
    }
  }

  const handleAddressSelection = (data: LookupItem) => {
    setAddressFields(data.value)
  }

  if (isDisabled) {
    return <ReadOnlyField isSkeleton={isSkeleton} labelledBy={labelledBy} value={address} />
  }

  return (
    <Typeahead
      {...props}
      isSkeleton={isSkeleton}
      selectType='async'
      labelledBy={labelledBy}
      handleChange={handleAddressSelection}
      selectedOption={{ label: address, value: address }}
      options={[]}
      loadOptions={loadOptions}
      isClearable
      isDisabled={isDisabled}
      autoFocus={autoFocus}
      menuPortalTarget={menuPortalTarget}
    />
  )
}
