import { ColumnHeader } from '@msaf/core-react'
import { toDollarCurrencyFormat, toDollarCurrencyFormatter } from './utils'
import { sqmToHa } from '../utils/units'

export const PLANTING_TYPES = [
  'coastalPlanting',
  'riparianPlanting',
  'wetlandPlanting',
  'hillCountryPlanting',
  'hillCountryAlternativeForestry',
  'hillCountrySilvopasturePlanting',
]

export const DEFAULT_PLANT_SPACING_STEMS_PER_HA = '2500'
export const DEFAULT_PLANT_SPACING_STEMS_PER_HA_SILVOPASTURE = '100'
export const DEFAULT_AVERAGE_PRICE_PER_PLANT = '2.10'
export const DEFAULT_AVERAGE_PRICE_PER_PLANT_SILVOPASTURE = '0.00'
export const MAX_PRICE_PER_PLANT = '6'
export const MAX_PRICE_PER_PLANT_SILVOPASTURE = '45'

export const PLANTING_TABLE_COLUMNS: ColumnHeader[] = [
  {
    elementKey: 'id',
    columnHeading: 'Id',
    viewColumn: 'id',
    type: 'hidden',
  },
  {
    elementKey: 'locationName',
    columnHeading: 'Location name',
    viewColumn: 'locationName',
    sortable: true,
    type: 'text',
  },
  {
    elementKey: 'plantingType',
    columnHeading: 'Planting type',
    viewColumn: 'plantingType.label',
    sortable: true,
    type: 'text',
  },
  {
    elementKey: 'area',
    columnHeading: 'Area (ha)',
    viewColumn: 'area',
    sortable: true,
    type: 'text',
    fieldFormatter: sqmToHa,
  },
  {
    elementKey: 'numberOfPlants',
    columnHeading: '# Plants',
    viewColumn: 'numberOfPlants',
    sortable: true,
    type: 'text',
  },
  {
    elementKey: 'proposedCost',
    columnHeading: 'Total Cost',
    viewColumn: 'proposedCost',
    sortable: true,
    type: 'text',
    fieldFormatter: toDollarCurrencyFormat,
  },
  {
    elementKey: 'materialCost',
    columnHeading: 'Plant Cost',
    viewColumn: 'materialCost',
    sortable: true,
    type: 'text',
    fieldFormatter: toDollarCurrencyFormat,
  },
  {
    elementKey: 'labourCost',
    columnHeading: 'Labour Cost',
    viewColumn: 'labourCost',
    sortable: true,
    type: 'text',
    fieldFormatter: toDollarCurrencyFormat,
  },
]

export const SITE_PREPARATION_TABLE_COLUMNS: ColumnHeader[] = [
  {
    elementKey: 'id',
    columnHeading: 'Id',
    viewColumn: 'id',
    type: 'hidden',
  },
  {
    elementKey: 'locationName',
    columnHeading: 'Location name',
    viewColumn: 'locationName',
    sortable: true,
    type: 'text',
  },
  {
    elementKey: 'type',
    columnHeading: 'Type',
    viewColumn: 'type',
    sortable: true,
    type: 'text',
  },
  {
    elementKey: 'maintenanceType',
    columnHeading: 'Preparation type',
    viewColumn: 'maintenanceType',
    sortable: true,
    type: 'text',
  },
  {
    elementKey: 'area',
    columnHeading: 'Area (ha)',
    viewColumn: 'area',
    sortable: true,
    type: 'text',
    fieldFormatter: sqmToHa,
  },
  {
    elementKey: 'materialPrice',
    columnHeading: 'Price per unit',
    viewColumn: 'materialPrice',
    sortable: false,
    type: 'text',
    fieldFormatter: toDollarCurrencyFormatter(3),
  },
  {
    elementKey: 'proposedCost',
    columnHeading: 'Cost',
    viewColumn: 'proposedCost',
    sortable: false,
    type: 'text',
    fieldFormatter: toDollarCurrencyFormat,
  },
]

export const SITE_MAINTENANCE_TABLE_COLUMNS: ColumnHeader[] = [
  {
    elementKey: 'id',
    columnHeading: 'Id',
    viewColumn: 'id',
    type: 'hidden',
  },
  {
    elementKey: 'locationName',
    columnHeading: 'Location name',
    viewColumn: 'locationName',
    sortable: true,
    type: 'text',
  },
  {
    elementKey: 'type',
    columnHeading: 'Type',
    viewColumn: 'type',
    sortable: true,
    type: 'text',
  },
  {
    elementKey: 'maintenanceType',
    columnHeading: 'Maintenance type',
    viewColumn: 'maintenanceType',
    sortable: true,
    type: 'text',
  },
  {
    elementKey: 'area',
    columnHeading: 'Area (ha)',
    viewColumn: 'area',
    sortable: true,
    type: 'text',
    fieldFormatter: sqmToHa,
  },
  {
    elementKey: 'sprayCount',
    columnHeading: 'Spray count',
    viewColumn: 'sprayCount',
    sortable: true,
    type: 'text',
  },
  {
    elementKey: 'materialPrice',
    columnHeading: 'Price per unit',
    viewColumn: 'materialPrice',
    sortable: false,
    type: 'text',
    fieldFormatter: toDollarCurrencyFormatter(3),
  },
  {
    elementKey: 'proposedCost',
    columnHeading: 'Cost',
    viewColumn: 'proposedCost',
    sortable: false,
    type: 'text',
    fieldFormatter: toDollarCurrencyFormat,
  },
]

export const GATE_TROUGH_TABLE_COLUMNS: ColumnHeader[] = [
  {
    elementKey: 'id',
    columnHeading: 'Id',
    viewColumn: 'id',
    type: 'hidden',
  },
  {
    elementKey: 'locationName',
    columnHeading: 'Location name',
    viewColumn: 'locationName',
    sortable: true,
    type: 'text',
  },
  {
    elementKey: 'unitCount',
    columnHeading: 'Number of units',
    viewColumn: 'unitCount',
    sortable: true,
    type: 'text',
  },
]

export const FENCING_TABLE_COLUMNS: ColumnHeader[] = [
  {
    elementKey: 'id',
    columnHeading: 'Id',
    viewColumn: 'id',
    type: 'hidden',
  },
  {
    elementKey: 'locationName',
    columnHeading: 'Location name',
    viewColumn: 'locationName',
    sortable: true,
    type: 'text',
  },
  {
    elementKey: 'fenceType',
    columnHeading: 'Fence type',
    viewColumn: 'fenceType',
    sortable: true,
    type: 'text',
  },
  {
    elementKey: 'terrainDifficulty',
    columnHeading: 'Difficulty',
    viewColumn: 'terrainDifficulty',
    sortable: true,
    type: 'text',
  },
  {
    elementKey: 'length',
    columnHeading: 'Length (m)',
    viewColumn: 'length',
    sortable: true,
    type: 'text',
  },
  {
    elementKey: 'materialPrice',
    columnHeading: '$/metre',
    viewColumn: 'materialPrice',
    sortable: true,
    type: 'text',
    fieldFormatter: toDollarCurrencyFormatter(2),
  },
  {
    elementKey: 'proposedCost',
    columnHeading: 'Cost',
    viewColumn: 'proposedCost',
    sortable: false,
    type: 'text',
    fieldFormatter: toDollarCurrencyFormat,
  },
]
