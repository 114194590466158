import { INTERNAL_DATE_FORMAT, formatDate } from '@msaf/core-common'
import { Button, DateField as DatePicker, FormField, InputWidth, Modal } from '@msaf/core-react'
import { parseDate } from '@msaf/generic-search-common'
import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { ActionsMenuItemWithId, useAppContext } from '../contexts/app-context'
import { useMutationWithToast } from '../hooks/use-mutation-with-toast'
import { usePlanSummary } from '../hooks/usePlanSummary'
import { useRequest } from '../hooks/useRequest'
import { ApplicationStatusFields } from '../routes/plans/[mode]/[id]/application/[applicationId]/submission'
import { usePermission } from '../services/permissions'
import { UserPermissions } from '../types/permissions'
import { ApplicationRouteParams, RouteMode } from '../types/route'
import { useRedirect } from './gatekeeper'
import { useFeatureFlags } from '../hooks/use-feature-flags'
import { DISPLAY_DATE_FORMAT } from '../constants'

function useRegisterMenuApplicationActions(setWithdrawModalOpen: CallableFunction) {
  const { id: planId, mode, applicationId } = useParams<ApplicationRouteParams>()
  const { addPageActions, removePageActions } = useAppContext()
  const { client } = useRequest()
  const { refetch: refetchPlanSummary } = usePlanSummary()
  const hasPermission = usePermission([UserPermissions.REOPEN_APPLICATION])
  const navigateToEdit = useRedirect({ mode: RouteMode.EDIT })
  const navigateToView = useRedirect({ mode: RouteMode.VIEW })

  const url = `/api/plan/${planId}/project/${applicationId}/status`

  const { data: statusData, refetch: refetchStatus } = useQuery<ApplicationStatusFields>(
    [url, mode],
    async () => (await client.get(url, {})).data,
  )

  const refetch = useCallback(() => {
    refetchStatus()
    refetchPlanSummary()
  }, [refetchPlanSummary, refetchStatus])

  const reopenMutation = useMutationWithToast({
    method: 'POST',
    url: `/api/plan/${planId}/project/${applicationId}/reopen`,
    success: {
      action: () => {
        refetch()
        navigateToEdit()
      },
      toastMessage: 'Project reopened for edit.',
    },
    error: { toastMessage: 'Failed to reopen project.' },
  })

  const closeMutation = useMutationWithToast({
    method: 'POST',
    url: `/api/plan/${planId}/project/${applicationId}/reclose`,
    success: {
      action: () => {
        refetch()
        navigateToView()
      },
      toastMessage: 'Project status has been reverted.',
    },
    error: { toastMessage: 'Failed to revert project status.' },
  })

  const resumeMutation = useMutationWithToast({
    method: 'POST',
    url: `/api/plan/${planId}/project/${applicationId}/resume`,
    success: {
      action: () => {
        refetch()
        navigateToView()
      },
      toastMessage: 'Project has been resumed.',
    },
    error: { toastMessage: 'Failed to resume project.' },
  })

  const { hasFlag } = useFeatureFlags()
  const featureWithdrawProjectsEnabled = hasFlag('withdraw_projects')

  useEffect(() => {
    const actions: ActionsMenuItemWithId[] = []
    if (hasPermission && statusData) {
      if (statusData.availableActions.includes('reopen'))
        actions.push({
          id: 'project-reopen',
          label: 'Reopen project',
          action: () => reopenMutation.mutate({}),
        })

      if (statusData.availableActions.includes('reclose'))
        actions.push({
          id: 'project-reclose',
          label: 'Revert project status',
          action: () => closeMutation.mutate({}),
        })

      if (featureWithdrawProjectsEnabled) {
        if (statusData.availableActions.includes('withdraw'))
          actions.push({
            id: 'project-withdraw',
            label: 'Withdraw project',
            action: () => setWithdrawModalOpen(),
          })

        if (statusData.availableActions.includes('resume'))
          actions.push({ id: 'project-resume', label: 'Resume project', action: () => resumeMutation.mutate({}) })
      }

      addPageActions?.(actions)
    }
    return () => {
      removePageActions?.(actions.map((a) => a.id))
    }
  }, [hasPermission, statusData?.availableActions])

  return { refetch, statusData }
}

export const ProjectActions = () => {
  const { id, applicationId } = useParams<ApplicationRouteParams>()
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false)
  const [modalRef, setModalRef] = useState<HTMLDivElement | undefined>()
  const navigateToView = useRedirect({ mode: RouteMode.VIEW })
  const [withdrawDate, setWithdrawDate] = useState(new Date())

  const { refetch, statusData } = useRegisterMenuApplicationActions(() => setWithdrawModalOpen(true))

  const withdrawMutation = useMutationWithToast<void, void, { startDate: string }, void>({
    method: 'POST',
    url: `/api/plan/${id}/project/${applicationId}/withdraw`,
    success: {
      action: () => {
        refetch()
        navigateToView()
      },
      toastMessage: 'Project has been withdrawn.',
    },
    error: { toastMessage: 'Failed to withdraw project.' },
  })
  return (
    <Modal
      contentRef={(ref) => setModalRef(ref)}
      isOpen={withdrawModalOpen}
      onRequestClose={() => setWithdrawModalOpen(false)}
      heading='Withdraw the project'
      body={
        <>
          <FormField labelId='1234' label='Please provide a date for when the project was withdrawn.'>
            <DatePicker
              menuPortalTarget={modalRef}
              dateFormat={DISPLAY_DATE_FORMAT}
              fieldSize={InputWidth.large}
              selectedDate={withdrawDate}
              setSelectedDate={(date: Date) => {
                setWithdrawDate(date)
              }}
              maxDate={new Date()}
              minDate={statusData?.isMigrated ? null : parseDate(statusData?.createdDate)}
            />
          </FormField>
        </>
      }
      contentLabel='Confirm feature deletion'
      buttons={
        <>
          <Button
            buttonStyle='primary'
            type='button'
            label='Withdraw'
            onClick={() => {
              withdrawMutation.mutate({ startDate: formatDate(withdrawDate, INTERNAL_DATE_FORMAT) })
              setWithdrawModalOpen(false)
            }}
          />
          <Button buttonStyle='text-action' type='button' label='Cancel' onClick={() => setWithdrawModalOpen(false)} />
        </>
      }
    />
  )
}
