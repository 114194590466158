import { Button } from '@msaf/core-react'
import { Feature, Geometry } from '@turf/helpers'
import { useContext, useMemo } from 'react'
import { useQuery } from 'react-query'
import { generatePath, useLocation, useOutletContext, useParams } from 'react-router-dom'
import { collectionFromFeature } from '../../../../../../../../components/feature-map/utils'
import { DisplayContext } from '../../../../../../../../components/gatekeeper'
import { APPLICATION_MAP_PATH, ErrorCode } from '../../../../../../../../constants'
import { useApplicationState } from '../../../../../../../../contexts/application-context'
import { SAVE_AND_CONTINUE_ID } from '../../../../../../../../forms/components/atom/footer'
import { Form } from '../../../../../../../../forms/components/form'
import { FeatureFormMode } from '../../../../../../../../forms/types'
import { useStateManagement } from '../../../../../../../../hooks/use-state-management'
import { useRequest } from '../../../../../../../../hooks/useRequest'
import { usePermission } from '../../../../../../../../services/permissions'
import { LookupItem } from '../../../../../../../../types'
import { EDIT_APPLICATION_PROGRESS_PERMISSIONS } from '../../../../../../../../types/permissions'
import { ActionRouteParams, RouteMode } from '../../../../../../../../types/route'
import { useFormConfig as useActionFormConfig } from '../../action/[actionId]/action.form'
import { useActionVerificationFormConfig } from './action-verification.form'
import { getActionVariationInitialState } from './utils'
import { FeatureType } from '../../../../../../../../config/map'

export interface ActionVerificationFields {
  actionType: FeatureType
  actionSubtype: string
  inspectionDate: string
  completionStatus: LookupItem
  completionDetails: string
  estimatedPlantSurvivalPercentage?: number
  hasVariation: boolean
  actualCost?: number
  numberOfPlants?: number
  area: number
  proposedArea: number
  length: number
  proposedLength: number
  actualNumberOfPlants?: number
  actualFenceType?: Partial<LookupItem>
  actualMaintenanceType?: Partial<LookupItem>
  proposedCost: number
}

export const ActionVerification = () => {
  const { canEditPlan } = useOutletContext<{ canEditPlan: boolean }>()
  const canEdit = usePermission(EDIT_APPLICATION_PROGRESS_PERMISSIONS) && canEditPlan

  const { isMigrated } = useApplicationState()
  const { setError } = useContext(DisplayContext)
  const { id: planId, mode, applicationId, actionId } = useParams<ActionRouteParams>()
  const { pathname } = useLocation()

  const { client } = useRequest()

  const { data: actionData, isLoading: actionIsLoading } = useQuery<Feature<Geometry>>(
    ['action', planId, actionId],
    () => client.get(`/api/plan/${planId}/features/${actionId}`).then((response) => response.data),
  )

  const { data, isLoading, errors, onSubmit, isEditable } = useStateManagement<ActionVerificationFields>(
    ([id, applicationId]) => `/api/plan/${id}/project/${applicationId}/action-verification/${actionId}`, // Should be action details
    {
      workflowId: 'progress',
      sectionId: 'progress',
      formId: 'actionVerification',
      instanceId: `${applicationId}`,
      subFormId: actionId,
    },
    {
      success: 'Action saved successfully',
      error: 'Unable to save Action',
    },
  )

  const actionConfig = useActionFormConfig(
    planId,
    actionData?.properties?.type,
    actionData?.properties,
    FeatureFormMode.VERIFICATION_FORM,
  )

  const combinedData = { ...actionConfig.initialState, ...data }
  const initialState = getActionVariationInitialState(combinedData)

  const returnPath = `/plans/${mode}/${planId}/progress/project/${applicationId}/sign-off`
  const mapPath = `${generatePath(APPLICATION_MAP_PATH, {
    id: planId,
    mode,
    applicationId,
  })}?origin=${pathname}`
  const collection = useMemo(() => collectionFromFeature(actionData), [actionData])
  const formConfig = useActionVerificationFormConfig({
    actionConfig: actionConfig.formConfig,
    returnPath,
    mapPath,
    data: initialState,
    features: collection,
    isMigrated,
  })

  if (applicationId == null || planId == null) {
    setError(ErrorCode.NOT_FOUND_404)
    return null
  }

  return (
    <Form
      mode={mode ?? RouteMode.VIEW}
      canEdit={canEdit && !!isEditable}
      initialState={initialState}
      isSkeleton={actionIsLoading || isLoading}
      errors={errors}
      submitAction={(data) => onSubmit(data)}
      nextPath={returnPath}
      footerActions={
        mode === RouteMode.EDIT ? (
          <>
            <Button id={SAVE_AND_CONTINUE_ID} type='submit' buttonStyle='primary' label='Save and return' />
            <Button buttonStyle='text-action' label='Cancel' path={returnPath} />
          </>
        ) : undefined
      }
      config={formConfig}
    />
  )
}
