import { useMemo } from 'react'
import { FormElementConfig } from '../../../../../../forms/types'
import { LookupItem } from '../../../../../../types'
import { AppNoteType, NoteForm } from '../../../../../../types/notes'

export interface CatchmentContextFields {
  regionalCouncil: Partial<LookupItem>
  territorialAuthority: Partial<LookupItem>
  freshwaterManagementUnit: Partial<LookupItem>
  surfaceWaterCatchments: Array<Partial<LookupItem>>
  relevantAwaWaterways: Array<Partial<LookupItem>>
  relevantAwaWaterwaysLocalNames: Array<{ value: string | null }>
  ecologicalDistricts: Array<Partial<LookupItem>>
  localMaraes: Array<Partial<LookupItem>>
  generalObservations: string
  priorities: string
  notes: Array<AppNoteType>
}

export const useCatchmentContextForm = (
  id: string | undefined,
  data: CatchmentContextFields | undefined,
  isMigrated: boolean | undefined,
): FormElementConfig<CatchmentContextFields>[] => {
  return useMemo(
    () => [
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Catchment scale priorities' },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          labelOverride: 'Catchment priorities',
          fieldId: 'priorities',
        },
      },
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Sub-catchment/local area details' },
      },
      {
        type: 'field',
        element: {
          type: 'lookup',
          fieldId: 'regionalCouncil',
          lookupId: 'regionalCouncil',
          defaultValue: {},
        },
      },
      {
        type: 'field',
        element: {
          type: 'lookup',
          fieldId: 'territorialAuthority',
          lookupId: 'territorialAuthority',
          defaultValue: {},
        },
      },
      {
        type: 'field',
        element: {
          type: 'lookup',
          fieldId: 'freshwaterManagementUnit',
          lookupId: 'freshwaterManagementUnit',
          defaultValue: {},
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'surfaceWaterCatchments',
          type: 'lookup',
          lookupId: 'surfaceWaterCatchment',
          repeating: true,
          isRequired: !isMigrated,
          defaultValue: {},
          helpText: 'The surface water catchments layer can be viewed in the Map tab',
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'relevantAwaWaterways',
          type: 'lookup',
          lookupId: 'waterways',
          repeating: true,
          isRequired: !isMigrated,
          defaultValue: {},
          helpText:
            "The river network layer can be viewed in the Map tab. If unable to identify then select 'Other' and provide the local name(s) below.",
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'relevantAwaWaterwaysLocalNames',
          type: 'text',
          repeating: true,
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'ecologicalDistricts',
          type: 'lookup',
          lookupId: 'ecologicalDistrict',
          repeating: true,
          isRequired: !isMigrated,
          defaultValue: {},
          helpText: 'The ecological district layer can be viewed in the Map tab',
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'localMaraes',
          type: 'lookup',
          lookupId: 'localMarae',
          repeating: true,
          defaultValue: {},
          helpText: 'The marae layer can be viewed in the Map tab',
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'generalObservations',
          type: 'text-area',
          helpText:
            'Please elaborate on the specific local catchment priorities, or any local knowledge or histories. This can include your own observations e.g. relating to intensification, afforestation, or waterway health.',
          isRequired: !isMigrated,
        },
      },
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      {
        type: 'field',
        element: {
          type: 'notes',
          fieldId: 'notes',
          form: NoteForm.CATCHMENT_CONTEXT,
        },
      },
    ],
    [data, id, isMigrated],
  ) as FormElementConfig<CatchmentContextFields>[]
}
