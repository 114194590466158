import { Outlet, useParams } from 'react-router-dom'
import ApplicationProvider from '../../../../../../contexts/application-context'
import { useFormData } from '../../../../../../hooks/use-form-state'
import { ApplicationRouteParams } from '../../../../../../types/route'
import { ApplicationStatusFields } from './submission'
import { useAddGenerateExportMenu } from '../../../../../../hooks/use-add-generate-export-menu'

export const Application = () => {
  const { id: planId, applicationId } = useParams<ApplicationRouteParams>()
  const applicationState = useFormData<ApplicationStatusFields>(`/api/plan/${planId}/project/${applicationId}/status`)

  useAddGenerateExportMenu(applicationState.data)

  return (
    <ApplicationProvider applicationState={{ ...applicationState.data }}>
      <Outlet />
    </ApplicationProvider>
  )
}
