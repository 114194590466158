import { RepeatableSection, RepeatableSectionProps } from '@msaf/core-react'
import { PropsWithChildren } from 'react'
import { ArrayPath, FieldValues, Path, useFieldArray, useFormContext } from 'react-hook-form'
import { RouteMode } from '../../../../types/route'
import { FieldConfig, RepeatingSectionProps } from '../../../types'
import { GenericField } from '../generic-field'

export type WrapperProps<T extends FieldValues> = PropsWithChildren<RepeatingSectionProps<T>> &
  RepeatableSectionProps & {
    removeField?: (index: number) => void
    addField?: () => void
  }

export function GenericRepeatingSection<T extends FieldValues>(props: WrapperProps<T>) {
  const { fieldId, heading, sectionName, fields, mode, disableNumberedSections, allowEmpty, helpText } = props
  const { control } = useFormContext<T>()
  const fieldArray = useFieldArray({ name: fieldId as ArrayPath<T>, control })

  return (
    <RepeatableSection
      heading={heading}
      helpText={helpText}
      sectionName={sectionName}
      disableNumberedSections={disableNumberedSections}
      addField={mode === RouteMode.EDIT ? () => fieldArray.append({}) : undefined}
      removeField={
        mode === RouteMode.EDIT
          ? (index) => {
              fieldArray.remove(index)
            }
          : undefined
      }
      allowEmpty={allowEmpty}
    >
      {fieldArray.fields.map((field, index) => {
        return (
          <div key={field.id}>
            {fields.map((formField, formFieldIndex) => (
              <GenericField<T>
                mode={mode ?? RouteMode.VIEW}
                key={formFieldIndex}
                element={{
                  ...(formField as FieldConfig<T>),
                  fieldId: `${fieldId}.${index}.${formField.fieldId}` as Path<T>,
                }}
              />
            ))}
          </div>
        )
      })}
    </RepeatableSection>
  )
}
