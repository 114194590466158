import { Button, Modal } from '@msaf/core-react'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import { SAVE_AND_CONTINUE_ID } from '../../../../../../../../forms/components/atom/footer'
import { Form } from '../../../../../../../../forms/components/form'
import { usePostAction } from '../../../../../../../../hooks/use-post-action'
import { useStateManagement } from '../../../../../../../../hooks/use-state-management'
import { usePermission } from '../../../../../../../../services/permissions'
import { UserPermissions } from '../../../../../../../../types/permissions'
import { ApplicationRouteParams, RouteMode } from '../../../../../../../../types/route'
import { RequestForPaymentFields, useFormConfig } from './payment-summary.form'
import { useState } from 'react'
import { errorsAsFormConfig } from '../../../../../../../../forms/utils'
import { useWorkflowState } from '../../../../../../../../validation/hooks'
import { useBuildWorkflowErrors } from '../../../../../../../../hooks/use-build-workflow-errors'

export const PaymentsSummary = () => {
  const { id: planId, applicationId, mode } = useParams<ApplicationRouteParams>()
  const canEdit = usePermission(UserPermissions.EDIT_GRANT_PAYMENT)
  const [completeModalOpen, setCompleteModalOpen] = useState(false)

  const { data, workflowData, isLoading, isSaving, isEditable, onSubmit } = useStateManagement<RequestForPaymentFields>(
    ([planId, applicationId]) => `/api/plan/${planId}/project/${applicationId}/payment-summary`,
    {
      workflowId: 'payment',
      sectionId: 'payment',
      formId: 'requestsForPaymentSummary',
      instanceId: `${applicationId}`,
    },
    {
      success: 'Project saved successfully',
      error: 'Unable to save project',
    },
  )

  const completeAction = usePostAction<
    Partial<RequestForPaymentFields>,
    Partial<RequestForPaymentFields>,
    AxiosError<{ message: string | undefined }>
  >({
    url: `/api/plan/${planId}/project/${applicationId}/complete`,
    displayServerError: true,
    messages: {
      success: 'Project completed.',
      error: 'Could not complete project',
    },
  })

  /* This form is special as it needs to check the errors for its workflow and
   * the workflow prior to it (progress). */
  const workflowQuery = useWorkflowState(planId, 'progress', mode)
  const progressErrors = useBuildWorkflowErrors(workflowQuery.data, 'progress', 'progress')
  const paymentErrors = useBuildWorkflowErrors(workflowData, 'payment', 'payment')

  const config = [
    ...useFormConfig(data, mode),
    ...errorsAsFormConfig<RequestForPaymentFields>([...progressErrors, ...paymentErrors]),
  ]

  if (data) {
    data.dateContractEndsVariation = Boolean(data?.dateContractEndsVariationDate)
  }

  return (
    <>
      <Modal
        isOpen={completeModalOpen}
        onRequestClose={() => setCompleteModalOpen(false)}
        heading='Complete project?'
        body='Are you sure you wish to complete this project?'
        contentLabel='Confirm project completion'
        buttons={
          <>
            <Button
              buttonStyle='primary'
              type='button'
              label='Complete project'
              onClick={() => {
                completeAction.mutateAsync({})
                setCompleteModalOpen(false)
              }}
            />
            <Button
              buttonStyle='text-action'
              type='button'
              label='Cancel'
              onClick={() => setCompleteModalOpen(false)}
            />
          </>
        }
      />
      <Form<RequestForPaymentFields>
        isSkeleton={isLoading}
        mode={mode ?? RouteMode.VIEW}
        initialState={data}
        canEdit={canEdit && !!isEditable}
        submitAction={(data) => onSubmit(data)}
        config={config}
        nextPath={`/plans/view/${planId}/payments/project/${applicationId}/payments`}
        footerActions={
          mode === RouteMode.EDIT ? (
            <>
              <Button
                id={SAVE_AND_CONTINUE_ID}
                type='button'
                buttonStyle='primary'
                label='Complete project'
                onClick={() => setCompleteModalOpen(true)}
                isLoading={completeAction.isLoading}
                isDisabled={completeAction.isLoading}
              />
              <Button
                type='submit'
                buttonStyle='secondary'
                label='Save'
                isLoading={isLoading || isSaving}
                isDisabled={isLoading || isSaving}
              />
            </>
          ) : undefined
        }
      />
    </>
  )
}
