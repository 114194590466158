import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { FeatureMap } from '../../../../components/feature-map'
import { useViewRedirectGatekeeper } from '../../../../components/gatekeeper'
import { LoadingPage } from '../../../../components/loading-page'
import NotFound from '../../../../components/not-found'
import { PlanSummaryData } from '../../../../hooks/usePlanSummary'
import { useRequest } from '../../../../hooks/useRequest'
import { EDIT_PLAN_PERMISSIONS, UserPermissions } from '../../../../types/permissions'
import { RouteMode, RouteParams } from '../../../../types/route'

export type MapRouteParams = Pick<RouteParams, 'id'> & { mode: RouteMode.EDIT | RouteMode.VIEW | 'base' }

export const PlanMap = () => {
  const { id, mode } = useParams<MapRouteParams>()
  const permissions_url = `/api/plan/${id}/permissions`
  const { data: permissions } = useQuery<{ canEdit: boolean }>([permissions_url], () =>
    client.get(permissions_url).then((response) => response.data),
  )

  const planPermissions = useMemo(() => (!!permissions?.canEdit ? EDIT_PLAN_PERMISSIONS : []), [permissions?.canEdit])

  useViewRedirectGatekeeper(planPermissions, UserPermissions.VIEW_PLANS)
  const { client } = useRequest()

  const {
    data: planSummary,
    isSuccess,
    isLoading,
  } = useQuery<PlanSummaryData>([id], () => client.get(`/api/plan/${id}/summary`).then((res) => res.data))

  const isEditMode = useMemo(() => mode === RouteMode.EDIT, [mode])
  const isBase = useMemo(() => mode === 'base', [mode])

  if (!id) return null

  if (isLoading) return <LoadingPage header='Initialising Map' />

  if (!isSuccess) {
    return <NotFound />
  }

  return (
    <FeatureMap
      featureToggle={{
        create: isEditMode,
        edit: isEditMode,
        delete: isEditMode,
        view: !isBase,
      }}
      featuresUrl={`/api/plan/${id}/features`}
      center={planSummary.location ?? undefined}
      zoom={planSummary.location ? 16 : undefined}
      centerMode={'toFeatures'}
      actionUrlTemplate={`/plans/${mode}/${id}/plan/project/:applicationId/action/:actionId`}
      defaultMenuIsOpen={!isBase}
    />
  )
}
