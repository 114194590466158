import { useNavigate } from 'react-router-dom'
import { DEFAULT_CENTER } from '../../../../../../config/map'
import { AppNoteType, NoteForm } from '../../../../../../types/notes'
import { FieldWatchResultProps } from '../../../../../../forms/components/hooks/use-field-watch'
import { LabelledFieldConfig, FormElementConfig } from '../../../../../../forms/types'
import { LookupItem, RepeatingLookupItem } from '../../../../../../types'
import { useMemo } from 'react'
import { useLocation } from '@msaf/router-react'
import { Path, PathValue, UnpackNestedValue } from 'react-hook-form'
import { RouteMode } from '../../../../../../types/route'

export interface PropertySystemsAspirationsFields {
  isCouncilOrDocLand: boolean | null
  isSignificantNaturalArea: boolean | null
  significantNaturalAreaComment: string | null
  areaHa: number
  notes: AppNoteType[]
  excludedDueToTransferableTitles: boolean
  transferableTitlesComment: string | null
  sectors: RepeatingLookupItem
  landCovers: Array<{
    landCoverType: Partial<LookupItem> | null
    estPercentageCover: number | null
  }>
  stockIntensity: Partial<LookupItem>
  sustainabilityJourneyDescription: string
}

export const usePropertySystemsAspirationsConfig = (
  id?: string,
  data?: PropertySystemsAspirationsFields,
  mode?: RouteMode,
  location?: [number, number],
  isMigrated?: boolean | undefined,
): FormElementConfig<PropertySystemsAspirationsFields>[] => {
  const url = useLocation()
  const navigate = useNavigate()

  return useMemo(
    () => [
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Land size' },
      },
      {
        type: 'field',
        element: {
          type: 'map',
          center: location ?? DEFAULT_CENTER,
          zoom: 15,
          featuresUrl: `/api/plan/${id}/features`,
          filterFeatureTypes: ['propertyBoundary'],
          actions: [
            {
              iconAriaLabel: 'map',
              icon: 'map',
              label: `${mode === RouteMode.EDIT ? 'Add/edit' : 'View'} boundaries`,
              type: 'button',
              onClick: () => {
                navigate(`/plans/${mode}/${id}/map?origin=${url.pathname}`, {
                  state: {
                    selectedFeatureCategories: [
                      {
                        name: 'Property boundary',
                        value: 'propertyBoundary',
                      },
                    ],
                  },
                })
              },
            },
          ],
        },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          fieldId: 'areaHa',
          formPrefix: 'systemAspirations',
          conversion: (
            value: UnpackNestedValue<
              PathValue<PropertySystemsAspirationsFields, Path<PropertySystemsAspirationsFields>>
            >,
          ) => {
            return typeof value === 'number' ? `${value}ha` : '-'
          },
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'excludedDueToTransferableTitles',
          type: 'boolean',
          helpText:
            'Works supported by KMR funding cannot be used to support a subdivision consent or transferable title. Note: a project could be funded on another part of the property.',
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'transferableTitlesComment',
          type: data?.excludedDueToTransferableTitles ? 'text-area' : 'read-only',
          defaultValue: {},
          watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<PropertySystemsAspirationsFields>) => {
            if (info.type === 'change' && info.name === 'excludedDueToTransferableTitles') {
              if (!data?.excludedDueToTransferableTitles) {
                methods.setValue('transferableTitlesComment', null)
                return {
                  ...(initialConfig as LabelledFieldConfig<PropertySystemsAspirationsFields>),
                  type: 'read-only',
                }
              } else {
                return {
                  ...(initialConfig as LabelledFieldConfig<PropertySystemsAspirationsFields>),
                  type: 'text-area',
                }
              }
            }
            return initialConfig
          },
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'isCouncilOrDocLand',
          type: 'boolean',
        },
      },
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Landowner aspirations' },
      },
      {
        type: 'field',
        element: {
          fieldId: 'sustainabilityJourneyDescription',
          type: 'text-area',
          helpText:
            'Highlight efforts made to date and any aspirations for the property or practices. Cultural practices could include (but are not limited to) mahinga kai or cultural harvests. This may also include previous council projects on the property.',
          isRequired: !isMigrated,
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'hasExistingRelationshipWithKaitiakiOrMarae',
          type: 'text-area',
          isRequired: !isMigrated,
        },
      },
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Land use' },
      },
      {
        type: 'field',
        element: {
          fieldId: 'sectors',
          type: 'lookup',
          lookupId: 'sector',
          defaultValue: {},
          repeating: true,
          isRequired: !isMigrated,
        },
      },
      {
        type: 'field',
        element: {
          type: 'repeating-section',
          sectionName: 'Land cover',
          repeating: true,
          fieldId: 'landCovers',
          fields: [
            {
              type: 'lookup',
              fieldId: 'landCoverType',
              formPrefix: 'landCovers',
              lookupId: 'landCover',
              isRequired: !isMigrated,
            },
            {
              type: 'number',
              fieldId: 'estPercentageCover',
              formPrefix: 'landCovers',
              min: 0,
              max: 100,
              isRequired: !isMigrated,
            },
          ],
        },
      },
      {
        type: 'field',
        element: {
          type: 'lookup',
          fieldId: 'stockIntensity',
          lookupId: 'stockIntensity',
          defaultValue: {},
          helpText:
            'Ask the landowner whether their stock intensity is currently low, medium or high. This can help with understanding their property system and the relative priority of different remediation work.',
        },
      },
      {
        type: 'field',
        element: {
          type: 'notes',
          fieldId: 'notes',
          form: NoteForm.PROPERTY_SYSTEMS_ASPIRATIONS,
        },
      },
    ],
    [location, data, id, isMigrated],
  ) as FormElementConfig<PropertySystemsAspirationsFields>[]
}
