import { ActionsMenuItem } from '@msaf/core-react'
import { createContext, ReactNode, useContext, useReducer } from 'react'

interface AppContextState {
  pageActions?: ActionsMenuItemWithId[]
}

export type ActionId = string
export type ActionsMenuItemWithId = ActionsMenuItem & { id: ActionId }

interface AppContextActions {
  addPageActions?: (pageActions?: ActionsMenuItemWithId[] | ActionsMenuItemWithId) => void
  removePageActions?: (pageActions?: ActionId[] | ActionId) => void
}

type AppContextAction =
  | { type: 'add-actions'; pageActions?: ActionsMenuItemWithId[] }
  | { type: 'remove-actions'; pageActionIds?: ActionId[] }

const AppContext = createContext<AppContextState & AppContextActions>({})

export function useAppContext() {
  return useContext(AppContext)
}

const appContextReducer = (state: AppContextState, action: AppContextAction): AppContextState => {
  switch (action.type) {
    case 'add-actions':
      return { ...state, pageActions: [...(state.pageActions ?? []), ...(action.pageActions ?? [])] }
    case 'remove-actions': {
      const pageActions = state.pageActions?.filter(
        (existingAction) => !action.pageActionIds?.includes(existingAction.id),
      )
      return {
        ...state,
        pageActions,
      }
    }
    default:
      throw new Error('This action is not supported')
  }
}

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(appContextReducer, {})

  return (
    <AppContext.Provider
      value={{
        ...state,
        addPageActions: (pageActions) =>
          dispatch({
            type: 'add-actions',
            pageActions: pageActions && 'id' in pageActions ? [pageActions] : pageActions,
          }),
        removePageActions: (pageActions) =>
          dispatch({
            type: 'remove-actions',
            pageActionIds: typeof pageActions === 'string' ? [pageActions] : pageActions,
          }),
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
