import React from 'react'
import { ExportSearchResultsProps } from '..'

export function CookieAuthDownload({
  actionUrl,
  actionQuery,
  actionLabel,
  csrfToken,
}: Omit<ExportSearchResultsProps, 'requestOptions'>) {
  return (
    <form method='POST' action={actionUrl} target='_blank'>
      {csrfToken && <input type='hidden' name='csrfmiddlewaretoken' value={csrfToken} />}
      {actionQuery && <input type='hidden' name='query' value={JSON.stringify({ searchQuery: actionQuery })} />}
      <button type='submit' className='c-dropdown-menu__link'>
        {actionLabel}
      </button>
    </form>
  )
}
