import { AxiosError, AxiosResponse } from 'axios'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useApplicationState } from '../../../../../../../contexts/application-context'
import { usePlanState } from '../../../../../../../contexts/plan-context'
import { Form } from '../../../../../../../forms/components/form'
import { useFormState } from '../../../../../../../hooks/use-form-state'
import { useMutationWithToast } from '../../../../../../../hooks/use-mutation-with-toast'
import { ApplicationRouteParams, RouteMode } from '../../../../../../../types/route'
import { ApplicationDetailsFields, useApplicationDetailsConfig } from './config'

export function ApplicationDetails() {
  const { id: planId, applicationId, mode } = useParams<ApplicationRouteParams>()
  const { hasEditPermission } = usePlanState()
  const { isMigrated } = useApplicationState()
  const { data, isEditable, isLoading, errors, refetchErrors, refetchData } = useFormState<ApplicationDetailsFields>(
    () => `/api/plan/${planId}/project/${applicationId}/details`,
    {
      workflowId: 'application',
      sectionId: 'details',
      formId: 'details',
      instanceId: `${applicationId}`,
    },
  )

  const mutation = useMutationWithToast<
    AxiosResponse<ApplicationDetailsFields>,
    AxiosError<Map<keyof ApplicationDetailsFields, Array<string>>>,
    ApplicationDetailsFields,
    void
  >({
    method: 'PUT',
    url: `/api/plan/${planId}/project/${applicationId}/details`,
    success: {
      toastMessage: 'Project saved.',
      action: () => {
        // Refetch saved data to update the form data
        refetchData?.()
        // Refetch on success for any new validation errors
        refetchErrors?.()
      },
    },
    error: { toastMessage: 'Could not save project.' },
  })

  const onSubmit = async (data: ApplicationDetailsFields) => {
    await mutation.mutateAsync(data)
  }

  const config = useApplicationDetailsConfig({
    planId,
    applicationId,
    mode,
    data,
    isMigrated,
  })

  const initialState = useMemo(() => {
    return data
      ? {
          ...data,
          maintenanceSprayingActions: data?.maintenanceSprayingActions?.length ? data.maintenanceSprayingActions : [{}],
          sitePreparationActions: data?.sitePreparationActions?.length ? data.sitePreparationActions : [{}],
          plantingActions: data?.plantingActions?.length ? data.plantingActions : [{}],
          fencingActions: data?.fencingActions?.length ? data.fencingActions : [{}],
          gateTroughActions: data?.gateTroughActions?.length ? data.gateTroughActions : [{}],
          additionalFundingTags: data?.additionalFundingTags?.length ? data.additionalFundingTags : [{}],
          hasAdditionalFundingTags: !!data?.hasAdditionalFundingTags,
        }
      : undefined
  }, [data])

  return (
    <Form<ApplicationDetailsFields>
      initialState={initialState}
      isSkeleton={isLoading}
      errors={errors}
      mode={mode ?? RouteMode.VIEW}
      nextPath={`/plans/${mode}/${planId}/plan/project/${applicationId}/submission`}
      canEdit={hasEditPermission && (isEditable ?? false)}
      config={config}
      submitAction={onSubmit}
    />
  )
}
