import { GroupContainer, LabelledGroup, Tooltip } from '@msaf/core-react'
import { FeatureButton, MarkerIcon } from '@msaf/maps-react'
import { MAP_CONFIG } from '../../../../config/map'
import { useContext } from 'react'
import { FeatureMapContext } from '../../context'

interface AddFeatureProps {
  selectFeature: (key: string) => void
  selectedFeatureType: string | null
  isDisabled?: boolean
}

export function AddFeature({ selectFeature, selectedFeatureType, isDisabled }: AddFeatureProps) {
  const { filterGroups } = useContext(FeatureMapContext)
  const typeFilterGroup = filterGroups?.find((f) => f.key == 'type')

  return (
    <LabelledGroup
      labelText='Add an action'
      labelTag='legend'
      labelStyle='uppercase'
      labelTooltip={<Tooltip message='Select a type of feature to add to the map' appendTo={document.body} />}
    >
      <GroupContainer orientation='vertical'>
        {Object.entries(MAP_CONFIG.featureConfig)
          .filter((config) => typeFilterGroup?.filterObjects.find((f) => f.id === config[1].categoryKey)?.isSelected)
          .map(([key, feature]) => {
            const markerIcon = <MarkerIcon marker={feature} />
            return (
              <FeatureButton
                key={key}
                onClick={() => selectFeature(key)}
                iconElement={markerIcon}
                isActive={selectedFeatureType === key}
                isDisabled={isDisabled}
              >
                {feature.label}
              </FeatureButton>
            )
          })}
      </GroupContainer>
    </LabelledGroup>
  )
}
