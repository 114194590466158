import { AppLogoProps, Button, IconType, MAP_NAVIGATION_BREAKPOINT, useMediaQuery } from '@msaf/core-react'
import { SearchTemplate } from '@msaf/generic-search-common'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React, { ComponentType, useState } from 'react'
import { MapArea, MapAreaProps } from './components/map-area'
import { MapZoomControl } from './components/map-area/map-zoom-control'
import { MapContextPanel } from './components/map-context-panel'
import { MapContextContentProps } from './components/map-context-panel/map-context-panel-content'
import { MapHeader, MapHeaderProps } from './components/map-header'
import { MapMenuPanel } from './components/map-menu-panel'
import { MapNavBar } from './components/map-nav-bar'

export interface MapMenuConfig {
  menuName: string
  menuKey: string
  menuIcon: IconType
  badgeCount?: number
  menuComponent: JSX.Element
}

type WithContextPanel = {
  contextPanelConfig?: undefined
  contextPanel?: ComponentType<{ isContextPanelOpen: boolean; setIsContextPanelOpen: (isOpen: boolean) => void }>
}

type WithContextPanelConfig = {
  contextPanelConfig?: MapContextContentProps
  contextPanel?: undefined
}

export type MapPageProps = MapAreaProps &
  MapHeaderProps &
  (WithContextPanel | WithContextPanelConfig) & {
    appLogo?: AppLogoProps
    menuConfig: Array<MapMenuConfig>
    isMenuPanelOpen: boolean
    setIsMenuPanelOpen: (isOpen: boolean) => void
    isContextPanelOpen: boolean
    setIsContextPanelOpen: (isOpen: boolean) => void
    showMapPageHeader?: boolean
    searchTemplate?: SearchTemplate
  }

// Framer Motion animation properties for transparent overlay between menus and map (mobile only)
const overlayAnimationVariants = {
  visible: { opacity: 1, display: 'block' },
  hidden: { opacity: 0, display: 'none' },
}

export function MapPage({
  backLinkConfig,
  headerActionsConfig,
  appLogo,
  menuConfig,
  isMenuPanelOpen,
  setIsMenuPanelOpen,
  isContextPanelOpen,
  setIsContextPanelOpen,
  contextPanelConfig,
  contextPanel: ContextPanel,
  showMapPageHeader,
  searchTemplate,
  ...mapAreaProps
}: MapPageProps) {
  const isDesktop = useMediaQuery(`(min-width: ${MAP_NAVIGATION_BREAKPOINT})`)
  const [map, setMap] = useState<L.Map>()

  // Menu panel open by default on desktop, closed on mobile
  const [activeMenuKey, setActiveMenuKey] = useState(menuConfig[0]?.menuKey)

  const mapPageClasses = classNames('l-map-page', {
    'l-map-page--no-header': !showMapPageHeader,
  })

  // Control class names on panel containers to manage open/close state of menu UI
  const panelsContainerClasses = classNames('l-map-page__panels', {
    'l-map-page__panels--open': isMenuPanelOpen,
    'l-map-page__panels--no-header': !showMapPageHeader,
  })
  const menuPanelClasses = classNames('l-map-page__menu-panel', {
    'l-map-page__menu-panel--open': isMenuPanelOpen,
  })
  const contextPanelClasses = classNames('l-map-page__context-panel', {
    'l-map-page__context-panel--open': isContextPanelOpen,
  })
  const mapControlClasses = classNames('l-map-page__map-controls', {
    'l-map-page__map-controls-with-header': showMapPageHeader,
  })

  // Handles opening of menu panel and ensures correct menu is showing within it
  const openMenuPanel = (menuKey: string) => {
    setActiveMenuKey(menuKey)
    setIsMenuPanelOpen(true)

    // If on mobile, close context menu before opening menu panel:
    if (!isDesktop && !!setIsContextPanelOpen) {
      setIsContextPanelOpen(false)
    }
  }

  const closeMenuPanel = () => {
    setIsMenuPanelOpen(false)
  }

  return (
    <div className={mapPageClasses}>
      {showMapPageHeader && (
        <div className='l-map-page__header'>
          <MapHeader backLinkConfig={backLinkConfig} headerActionsConfig={headerActionsConfig} />
        </div>
      )}
      <div className='l-map-page__nav'>
        <MapNavBar
          appLogo={appLogo}
          menuConfig={menuConfig}
          activeMenuKey={activeMenuKey}
          isMenuPanelOpen={isMenuPanelOpen}
          openMenuPanel={openMenuPanel}
          closeMenuPanel={closeMenuPanel}
        />
        {!isDesktop && (isMenuPanelOpen || isContextPanelOpen) && (
          <AnimatePresence>
            <motion.div
              className='l-map-page__transparent-overlay'
              animate={isMenuPanelOpen || isContextPanelOpen ? 'visible' : 'hidden'}
              variants={overlayAnimationVariants}
              onClick={() => {
                closeMenuPanel()
              }}
            />
          </AnimatePresence>
        )}
        <div className={panelsContainerClasses}>
          <div className={menuPanelClasses}>
            <MapMenuPanel menuConfig={menuConfig} activeMenuKey={activeMenuKey} closeMenuPanel={closeMenuPanel} />
          </div>
          {/* If marker etc selected, output additional context panel */}
          {contextPanelConfig && (
            <div className={contextPanelClasses}>
              <MapContextPanel
                isPanelOpen={isContextPanelOpen}
                setIsPanelOpen={setIsContextPanelOpen}
                contextPanelConfig={contextPanelConfig}
              />
            </div>
          )}
          {ContextPanel && (
            <div className={contextPanelClasses}>
              <ContextPanel isContextPanelOpen={isContextPanelOpen} setIsContextPanelOpen={setIsContextPanelOpen} />
            </div>
          )}
        </div>
      </div>
      <div className={mapControlClasses}>
        {!showMapPageHeader && (
          <Button
            label='View list search'
            icon='table'
            path={searchTemplate?.mapConfig?.tableSearchRoute ?? '/'}
            buttonStyle='tertiary'
            iconAlignment='left'
          />
        )}
        {map && (
          <MapZoomControl
            zoomControlOptions={[
              {
                id: 'zoom-in',
                iconType: 'add',
                iconAriaLabel: 'Zoom in',
                zoomAction: () => map.setZoom(map.getZoom() + 1),
                noop: (event) => event.stopPropagation(),
              },
              {
                id: 'zoom-out',
                iconType: 'subtract',
                iconAriaLabel: 'Zoom out',
                zoomAction: () => map.setZoom(map.getZoom() - 1),
                noop: (event) => event.stopPropagation(),
              },
            ]}
          />
        )}
      </div>
      {/* TODO: wire up context menu clicks properly */}
      <div className='l-map-page__map'>
        <MapArea
          {...mapAreaProps}
          setMap={(map) => {
            // Set the map instance in the local state
            setMap(map)
            // Optionally set the map instance on the client
            mapAreaProps.setMap?.(map)
          }}
        />
      </div>
    </div>
  )
}
