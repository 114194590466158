import { useContext } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { DisplayContext } from '../../../../components/gatekeeper'
import { ReviewWorkflow } from '../../../../components/workflow-containers/review'
import { ErrorCode } from '../../../../constants'
import PlanProvider from '../../../../contexts/plan-context'
import { usePermission } from '../../../../services/permissions'
import { REVIEW_EDIT_PERMISSIONS } from '../../../../types/permissions'
import { RouteParams } from '../../../../types/route'

export const PlanReview = () => {
  const hasEditPermission = usePermission(REVIEW_EDIT_PERMISSIONS)

  const { id } = useParams<RouteParams>()
  const { setError } = useContext(DisplayContext)
  if (!id) {
    setError(ErrorCode.NOT_FOUND_404)
    return null
  }
  return (
    <PlanProvider planState={{ hasEditPermission }}>
      <ReviewWorkflow recordId={id}>
        <Outlet />
      </ReviewWorkflow>
    </PlanProvider>
  )
}
