import { Button, createToastMessage, ToastMessage } from '@msaf/core-react'
import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { usePlanState } from '../../../../../../../../contexts/plan-context'
import { useFormState } from '../../../../../../../../hooks/use-form-state'
import { useRequest } from '../../../../../../../../hooks/useRequest'
import { ApplicationRouteParams, RouteMode } from '../../../../../../../../types/route'
import { Form } from '../../../../../../../../forms/components/form'
import { ApplicationReviewFields, useApplicationReviewConfig } from './review.form'
import { usePermission } from '../../../../../../../../services/permissions'
import { UserPermissions } from '../../../../../../../../types/permissions'
import { useNavigate } from '@msaf/router-react'

export function ApplicationReview() {
  const { id: planId, applicationId, mode } = useParams<ApplicationRouteParams>()
  const { hasEditPermission } = usePlanState()
  const { client } = useRequest()
  const navigate = useNavigate()
  const hasSavePermissions = usePermission([
    UserPermissions.EDIT_REVIEW,
    UserPermissions.EDIT_REVIEW_ASSIGN_TEAM_MEMBER_ONLY,
  ])

  const { data, isLoading, errors, refetchErrors, refetchData, isEditable } = useFormState<ApplicationReviewFields>(
    () => `/api/plan/${planId}/project/${applicationId}/review`,
    {
      workflowId: 'review',
      sectionId: 'review',
      formId: 'review',
      instanceId: `${applicationId}`,
    },
  )

  const mutation = useMutation<
    AxiosResponse<ApplicationReviewFields>,
    AxiosError<Map<keyof ApplicationReviewFields, Array<string>>>,
    ApplicationReviewFields
  >((data) => client.put(`/api/plan/${planId}/project/${applicationId}/review`, data), {
    onSuccess: () => {
      createToastMessage(<ToastMessage messageType='success' title='Success' message='Project saved.' />)
      // Refetch saved data to update the form data
      refetchData?.()
      // Refetch on success for any new validation errors
      refetchErrors?.()
    },
    onError: () => {
      createToastMessage(<ToastMessage messageType='error' title='Error' message='Could not save project.' />)
    },
  })

  const onSubmit = async (data: ApplicationReviewFields) => {
    await mutation.mutateAsync(data)
  }

  const config = useApplicationReviewConfig(planId, applicationId, mode, data)
  const approvalRoute = `/plans/${mode}/${planId}/review/project/${applicationId}/approval`

  return (
    <Form<ApplicationReviewFields>
      initialState={data}
      isSkeleton={isLoading}
      errors={errors}
      mode={mode ?? RouteMode.VIEW}
      nextPath={`/plans/${mode}/${planId}/review/project/${applicationId}/approval`}
      canEdit={hasEditPermission && !!isEditable}
      config={config}
      submitAction={onSubmit}
      footerActions={
        !hasSavePermissions && mode === RouteMode.EDIT ? (
          <>
            <Button buttonStyle='primary' label='Continue' onClick={() => navigate(approvalRoute)} />
            <Button buttonStyle='text-action' label='Cancel' onClick={() => navigate('/')} />
          </>
        ) : undefined
      }
    />
  )
}
