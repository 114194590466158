import { MapCreationOptions } from '@msaf/maps-common'
import { MapPage } from '@msaf/maps-react'
import { useLocation, useNavigate } from '@msaf/router-react'
import { useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ViewFeaturesPanel } from '../view-features-panel'
import { DEFAULT_CENTER, DEFAULT_FEATURE_ZOOM_LEVEL, getBaseLayers, getLayersConfig } from '../../../config/map'
import { RouteMode, RouteParams } from '../../../types/route'
import { AddFeaturePanel } from '../add-feature-panel'
import { FeatureMapContext } from '../context'
import { ContextPanel } from '../context-panel'
import { EditFeaturesPanel } from '../edit-features-panel'
import { FeatureMapFeatureOptions } from '../types'
import { MAP_CONFIG_BASE } from './constants'
import { useRegisterFeatureFilterGroups } from '../feature-filter-groups/use-register-feature-filter-groups'
import L from 'leaflet'

export type PageMapOptions = Pick<MapCreationOptions, 'center' | 'zoom'>

export function Page({
  setMap,
  onSave,
  mapOptions,
  featureToggle,
  defaultMenuIsOpen = true,
  isLoading = false,
}: {
  setMap: (map: L.Map | undefined) => void
  onSave?: () => void
  mapOptions?: PageMapOptions
  featureToggle: FeatureMapFeatureOptions
  defaultMenuIsOpen?: boolean
  isLoading?: boolean
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const { mode, id } = useParams<RouteParams>()
  const originUrl = useMemo(() => {
    // If no origin, navigate to the pre-checks route.
    return new URLSearchParams(location.search).get('origin') ?? `/plans/${mode}/${id}/plan/pre-checks`
  }, [location])

  const [isMenuPanelOpen, setIsMenuPanelOpen] = useState(defaultMenuIsOpen)
  const { selectedFeature, clearFeatureSelection, filterGroups } = useContext(FeatureMapContext)

  const basemap = useMemo(() => getBaseLayers(), [])
  const layersConfig = useMemo(() => getLayersConfig(), [])

  useRegisterFeatureFilterGroups()

  const menus = useMemo(() => {
    const layers = []
    if (featureToggle.create) {
      layers.push({
        menuIcon: 'marquee',
        menuName: 'Add actions',
        menuKey: 'add-actions',
        menuComponent: <AddFeaturePanel setIsMenuPanelOpen={setIsMenuPanelOpen} />,
      })
    }

    const filterCount = filterGroups?.filter((group) => group.filterFunction).length

    if (featureToggle.edit || featureToggle.delete) {
      layers.push({
        menuIcon: 'layers',
        menuName: 'Edit actions',
        menuKey: 'edit-actions',
        badgeCount: filterCount,
        menuComponent: <EditFeaturesPanel setIsMenuPanelOpen={setIsMenuPanelOpen} />,
      })
    } else if (featureToggle.view !== false) {
      layers.push({
        menuIcon: 'layers',
        menuName: 'View actions',
        menuKey: 'view-actions',
        badgeCount: filterCount,
        menuComponent: <ViewFeaturesPanel setIsMenuPanelOpen={setIsMenuPanelOpen} />,
      })
    }

    return layers
  }, [filterGroups])

  return (
    <MapPage
      appLogo={MAP_CONFIG_BASE.appLogo}
      backLinkConfig={{
        label: 'Back',
        onClick: () => navigate(originUrl),
      }}
      headerActionsConfig={
        mode === RouteMode.EDIT && onSave
          ? [
              {
                key: 'saveBtn',
                label: 'Save',
                onClick: onSave,
                isDisabled: isLoading,
              },
            ]
          : []
      }
      setMap={(map) => {
        map?.addControl(layersConfig.control)
        map?.addLayer(layersConfig.defaultBasemap)
        layersConfig.defaultLayers.forEach((layer) => {
          map?.addLayer(layer)
        })
        map && L.control.scale({ position: 'bottomright', imperial: false }).addTo(map)

        setMap(map)
      }}
      mapOptions={{
        center: mapOptions?.center ?? DEFAULT_CENTER,
        editable: true,
        baseLayers: basemap,
        zoom: mapOptions?.zoom ?? DEFAULT_FEATURE_ZOOM_LEVEL,
        zoomControl: false,
        // Increase the click tolerance to ensure that clicking on fencing is possible.
        renderer: new L.Canvas({ tolerance: 2 }),
      }}
      menuConfig={menus}
      contextPanel={ContextPanel}
      isMenuPanelOpen={isMenuPanelOpen}
      setIsMenuPanelOpen={setIsMenuPanelOpen}
      isContextPanelOpen={selectedFeature != null}
      setIsContextPanelOpen={() => clearFeatureSelection?.()}
      showMapPageHeader
    />
  )
}
