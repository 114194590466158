import { formatDate, INTERNAL_DATE_FORMAT } from '@msaf/core-common'
import { DateField as DatePicker, InputWidth } from '@msaf/core-react'
import { parseDate } from '@msaf/generic-search-common'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import { RouteMode } from '../../../types/route'
import { DateFieldProps } from '../../types'
import { Wrapper } from './wrapper'
import { DISPLAY_DATE_FORMAT } from '../../../constants'

export function DateField<T extends FieldValues>(props: DateFieldProps<T>) {
  const { fieldId, maxDate, minDate, mode, isRequired, isDisabled, ...rest } = props
  const { control } = useFormContext<T>()

  return (
    <Wrapper {...rest} fieldId={fieldId} isRequired={isRequired}>
      <Controller
        control={control}
        name={fieldId}
        render={({ field }) => (
          <DatePicker
            isDisabled={mode === RouteMode.VIEW || isDisabled}
            isSkeleton={props.isSkeleton}
            dateFormat={DISPLAY_DATE_FORMAT}
            fieldSize={InputWidth.large}
            selectedDate={parseDate(field.value as string)}
            setSelectedDate={(date: Date) => {
              field.onChange(date ? formatDate(date, INTERNAL_DATE_FORMAT) : null)
            }}
            maxDate={parseDate(maxDate)}
            minDate={parseDate(minDate)}
            {...rest}
          />
        )}
      />
    </Wrapper>
  )
}
