import { Button } from '@msaf/core-react'
import { useMemo } from 'react'
import { useLocation, useOutletContext, useParams } from 'react-router-dom'
import { Form } from '../../../../../../../../forms/components/form'
import { usePostAction } from '../../../../../../../../hooks/use-post-action'
import { useStateManagement } from '../../../../../../../../hooks/use-state-management'
import { usePermission } from '../../../../../../../../services/permissions'
import { EDIT_APPLICATION_PROGRESS_PERMISSIONS, UserPermissions } from '../../../../../../../../types/permissions'
import { ApplicationRouteParams, RouteMode } from '../../../../../../../../types/route'
import { SignOffFields, SignOffObservationDetailsFields, useFormConfig } from './sign-off.form'
import { usePlanSummary } from '../../../../../../../../hooks/usePlanSummary'
import { DEFAULT_CENTER } from '../../../../../../../../config/map'

const setDefaultObservationType = (observationDetails: SignOffObservationDetailsFields) => ({
  ...observationDetails,
  observationType: observationDetails.observationType.length ? observationDetails.observationType : [{}],
})

const setDefaultObservationDetails = (data: SignOffFields) => ({
  ...data,
  observationDetails: data?.observationDetails?.length
    ? data.observationDetails.map(setDefaultObservationType)
    : [
        {
          observationType: [{}],
          dateAssessmentCompleted: null,
          generalNotes: null,
        },
      ],
})

export const SignOff = () => {
  const { id: planId, applicationId, mode } = useParams<ApplicationRouteParams>()
  const { canEditPlan } = useOutletContext<{ canEditPlan: boolean }>()
  const { location } = usePlanSummary()

  const canEdit =
    usePermission([...EDIT_APPLICATION_PROGRESS_PERMISSIONS, UserPermissions.ASSIGN_PROGRESS]) && canEditPlan

  const userCanAssign = usePermission([UserPermissions.EDIT_PROGRESS, UserPermissions.ASSIGN_PROGRESS])

  const notificationAction = usePostAction({
    url: `/api/plan/${planId}/project/${applicationId}/notify-progress`,
    displayServerError: true,
    messages: {
      success: 'Successfully notified KMR Maurikura. Edits and additional assessments can still be made.',
      error: 'Unable to notify KMR Maurikura.',
    },
  })

  const { data, isLoading, errors, onSubmit, isEditable } = useStateManagement<SignOffFields>(
    ([id, applicationId]) => `/api/plan/${id}/project/${applicationId}/sign-off`,
    {
      workflowId: 'progress',
      sectionId: 'progress',
      formId: 'signOff',
      instanceId: `${applicationId}`,
    },
    {
      success: 'Project saved successfully',
      error: 'Unable to save project',
    },
  )

  const initialState = useMemo(() => (data ? setDefaultObservationDetails(data) : null), [data])

  const config = useFormConfig(userCanAssign, location ?? DEFAULT_CENTER)

  const { pathname } = useLocation()

  const actionsEnabled = mode != RouteMode.EDIT && !isLoading && !notificationAction.isLoading

  return (
    <Form<SignOffFields>
      isSkeleton={isLoading}
      errors={errors}
      mode={mode ?? RouteMode.VIEW}
      initialState={initialState}
      canEdit={canEdit && !!isEditable}
      submitAction={(data) => onSubmit(data)}
      config={config}
      footerActions={
        <>
          {mode == RouteMode.EDIT && canEdit && !!isEditable ? (
            <>
              <Button
                label='Notify KMR'
                type='button'
                isDisabled={actionsEnabled}
                onClick={() => notificationAction.mutate({})}
                buttonStyle='secondary'
              />
              <Button key='save' label='Save' type='submit' isDisabled={actionsEnabled} />
            </>
          ) : null}
          {mode == RouteMode.VIEW && canEdit && !!isEditable ? (
            <Button key='edit' label='Edit' path={pathname.replace(`/${RouteMode.VIEW}/`, `/${RouteMode.EDIT}/`)} />
          ) : null}
          <Button
            key='cancel'
            type='button'
            buttonStyle='text-action'
            path='/'
            label={mode == RouteMode.EDIT ? 'Cancel' : 'Close'}
          />
        </>
      }
    />
  )
}
