import { formatDate, INTERNAL_DATE_FORMAT } from '@msaf/core-common'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../../../../../../../auth'
import {
  FENCING_TABLE_COLUMNS,
  GATE_TROUGH_TABLE_COLUMNS,
  PLANTING_TABLE_COLUMNS,
  SITE_MAINTENANCE_TABLE_COLUMNS,
  SITE_PREPARATION_TABLE_COLUMNS,
} from '../../../../../../../../forms/constants'
import { FormConfig } from '../../../../../../../../forms/types'
import { toDollarCurrencyFormat } from '../../../../../../../../forms/utils'
import { LookupItem } from '../../../../../../../../types'
import { AppNoteType, NoteForm } from '../../../../../../../../types/notes'
import { ApplicationRouteParams, RouteMode } from '../../../../../../../../types/route'
import { useLocation } from '@msaf/router-react'
import { DEFAULT_CENTER } from '../../../../../../../../config/map'
import { useApplicationState } from '../../../../../../../../contexts/application-context'

export interface SignOffObservationDetailsFields {
  observationType: Partial<LookupItem>[]
  dateAssessmentCompleted: string | null
  generalNotes: string | null
}

export interface SignOffFields {
  fieldAdvisors: Partial<LookupItem>[]
  fieldAdvisorAssignedDate: string
  siteVisitNote: string
  notes: AppNoteType[]
  observationDetails: Array<SignOffObservationDetailsFields>
  totalCostsSummary: {
    totalActualCost: string
    totalProposedCost: string
  }
}

const FEATURE_TYPES = [
  {
    type: 'fencing',
    heading: 'Fencing',
    tableColumns: FENCING_TABLE_COLUMNS,
  },
  {
    type: 'gate-trough',
    heading: 'Gates and troughs',
    tableColumns: GATE_TROUGH_TABLE_COLUMNS,
  },
  {
    type: 'site-preparation',
    heading: 'Site preparation',
    tableColumns: SITE_PREPARATION_TABLE_COLUMNS,
  },
  {
    type: 'planting',
    heading: 'Planting',
    tableColumns: PLANTING_TABLE_COLUMNS,
    assistiveText:
      'You may use circular sample plots to cross-check your observations, using up to 1 per hectare of planting. These can support discussion with the landowner and help you to assess how well the area is establishing.',
  },
  {
    type: 'site-maintenance',
    heading: 'Site maintenance',
    tableColumns: SITE_MAINTENANCE_TABLE_COLUMNS,
  },
]

export const useFormConfig = (userCanAssign: boolean, location: [number, number]): FormConfig<SignOffFields> => {
  const url = useLocation()
  const navigate = useNavigate()
  const { id: planId, applicationId, mode } = useParams<ApplicationRouteParams>()
  const { user } = useAuth()
  const { isMigrated } = useApplicationState()

  return useMemo(
    () =>
      [
        {
          type: 'atom',
          element: {
            type: 'heading',
            content: 'Assigned field advisors',
            level: 2,
          },
        },
        {
          type: 'field',
          element: {
            type: 'lookup',
            fieldId: 'fieldAdvisors',
            lookupId: 'advisor',
            formPrefix: 'signOff',
            isRequired: true,
            repeating: true,
            defaultValue: {},
            allowDuplicates: false,
            mode: mode,
            isDisabled: !userCanAssign,
          },
        },
        {
          type: 'field',
          element: {
            type: 'date',
            fieldId: 'fieldAdvisorAssignedDate',
            maxDate: formatDate(new Date(), INTERNAL_DATE_FORMAT),
            isRequired: true,
            mode: userCanAssign ? mode : RouteMode.VIEW,
          },
        },
        {
          type: 'atom',
          element: { type: 'divider' },
        },
        {
          type: 'field',
          element: {
            type: 'repeating-section',
            heading: 'Project observations',
            sectionName: 'Project observation details',
            repeating: true,
            fieldId: 'observationDetails',
            helpText: 'Please capture information about each separate site visit assessment you make',
            fields: [
              {
                type: 'lookup',
                fieldId: 'observationType',
                lookupId: 'observationType',
                repeating: true,
                allowDuplicates: false,
              },
              {
                type: 'date',
                fieldId: 'dateAssessmentCompleted',
              },
              {
                type: 'text-area',
                fieldId: 'generalNotes',
              },
            ],
          },
        },
        {
          type: 'atom',
          element: {
            type: 'paragraph',
            content:
              'Information on each action for sign off can be accessed by clicking the map or the table rows below. This will allow you to assess whether the action is completed, if works are underway, or if the work has not been started.',
          },
        },
        {
          type: 'atom',
          element: {
            type: 'paragraph',
            content:
              'If the work has differed from what was planned you can capture these variations. Please confirm with KMR before varying any agreed price.',
          },
        },
        {
          type: 'atom',
          element: {
            type: 'paragraph',
            content:
              'Please also upload photo evidence for each action you sign off. This can also be done when clicking the row of each table below, or via the upload area at the bottom of this page.',
          },
        },
        {
          renderOnlyWhen: !isMigrated,
          type: 'field',
          element: {
            type: 'map',
            center: location ?? DEFAULT_CENTER,
            zoom: 15,
            featuresUrl: `/api/plan/${planId}/project/${applicationId}/features`,
            actions: [
              {
                iconAriaLabel: 'map',
                icon: 'map',
                label: 'Sign off via Map',
                type: 'button',
                onClick: () => {
                  navigate(
                    `/plans/${mode}/${planId}/progress/project/${applicationId}/action-verification/map?origin=${url.pathname}`,
                  )
                },
              },
            ],
          },
        },
        {
          type: 'atom',
          element: {
            type: 'divider',
          },
        },
        ...FEATURE_TYPES.flatMap(
          ({ type, heading, tableColumns, assistiveText }, index) =>
            [
              {
                type: 'atom',
                element: { type: 'heading', level: 2, content: heading },
              },
              {
                type: 'field',
                element: {
                  type: 'table',
                  currentUserPermissions: user?.permissions,
                  columnHeaders: [
                    ...tableColumns,
                    {
                      elementKey: 'variation',
                      columnHeading: 'Variation',
                      viewColumn: 'hasVariation',
                      sortable: true,
                      type: 'text',
                      fieldFormatter: (value) => (value ? 'Yes' : 'No'),
                    },
                    {
                      elementKey: 'completionStatus',
                      columnHeading: 'Completion Status',
                      viewColumn: 'completionStatus',
                      sortable: true,
                      type: 'text',
                    },
                  ],
                  defaultSortBy: {
                    orderColumn: 'locationName',
                    orderDirection: 'desc',
                  },
                  requestUrl: `/api/plan/${planId}/project/${applicationId}/feature/${type}`,
                  rowClickAction: {
                    label: 'View',
                    type: 'transitionAction',
                    args: [
                      { constant: `plans/${mode}/${planId}/progress/project/${applicationId}/action-verification` },
                      { elementKey: 'id' },
                    ],
                  },
                  noResultsMessage: `No ${heading.toLowerCase()}  actions added`,
                },
              },
              ...(assistiveText
                ? [
                    {
                      type: 'atom',
                      element: {
                        type: 'paragraph',
                        content: assistiveText,
                      },
                    },
                  ]
                : []),
              ...(index != FEATURE_TYPES.length - 1
                ? [
                    {
                      type: 'atom',
                      element: { type: 'divider' },
                    },
                  ]
                : []),
            ] as FormConfig<SignOffFields>,
        ),
        {
          type: 'atom',
          element: {
            type: 'divider',
          },
        },
        {
          type: 'atom',
          element: {
            type: 'heading',
            level: 2,
            content: 'Photos and documents',
          },
        },
        {
          type: 'field',
          element: {
            type: 'files',
            fieldId: 'files',
          },
        },
        {
          type: 'atom',
          element: {
            type: 'divider',
          },
        },
        {
          type: 'atom',
          element: {
            type: 'heading',
            content: 'Summary of costs',
            level: 2,
          },
        },
        {
          type: 'field',
          element: {
            type: 'read-only',
            fieldId: 'totalCostsSummary.totalProposedCost',
            conversion: toDollarCurrencyFormat,
          },
        },
        {
          type: 'field',
          element: {
            type: 'read-only',
            fieldId: 'totalCostsSummary.totalActualCost',
            conversion: toDollarCurrencyFormat,
          },
        },
        {
          type: 'field',
          element: {
            type: 'notes',
            fieldId: 'notes',
            form: NoteForm.SIGN_OFF,
          },
        },
      ] as FormConfig<SignOffFields>,
    [user, planId, applicationId, mode],
  )
}
