import { Properties } from '@turf/helpers'
import { DeepPartial, UnpackNestedValue } from 'react-hook-form'
import { FeatureType } from '../../config/map'
import labels from '../../constants/labels'
import { RouteMode } from '../../types/route'
import { FConfig, FieldWatchResultProps } from '../components/hooks/use-field-watch'
import { PLANTING_TYPES } from '../constants'
import {
  FeatureFormMode,
  FeatureFormFields,
  FeatureFormModeKeys,
  FormConfig,
  FormElementConfig,
  LibLookupFieldProps,
  TextFieldProps,
} from '../types'
import { convertElementToView, isFormModes } from '../utils'
import { getFencingFormConfig } from './fencing/config'
import { getGateTroughFormConfig } from './gate-trough/config'
import { getPlantingAreaFormConfig } from './planting-zone/config'
import { MAINTENANCE_LOOKUP_ID_MAP, getSiteMaintenanceFormConfig } from './site-maintenance/config'

export interface GetConfigForPlanActionType {
  actionType: FeatureType
  planId: string
  formMode: FeatureFormModeKeys
  routeMode?: RouteMode
  properties?: Properties
}

const isActionCompleted = (data: UnpackNestedValue<DeepPartial<FeatureFormFields>>) =>
  ['completed', 'partially completed'].includes(data.completionStatus?.label?.toLowerCase() ?? '')

export const getConfigForPlanActionType = ({
  actionType,
  planId,
  formMode,
  routeMode,
  properties,
}: GetConfigForPlanActionType) => {
  const initiallyRequiredValidationConfig = properties?.hasVariation
    ? {
        isRequired: true,
        isDisabled: false,
      }
    : {
        isRequired: false,
        isDisabled: true,
      }

  const { isMapMode, isVerificationMode } = isFormModes(formMode)

  // ========================================
  // Type specific fields
  // ========================================
  const formConfigParams = { planId, formMode, data: properties as FeatureFormFields }

  let showArea = false
  let showLength = false

  let actionSpecificFields = [] as FormConfig<FeatureFormFields>
  switch (actionType) {
    case 'gate':
    case 'trough':
      actionSpecificFields = getGateTroughFormConfig(formConfigParams) as FormConfig<FeatureFormFields>
      break
    case 'riparianPlanting':
    case 'wetlandPlanting':
    case 'coastalPlanting':
    case 'hillCountryPlanting':
    case 'hillCountryNativeRegeneration':
    case 'hillCountryAlternativeForestry':
    case 'hillCountrySilvopasturePlanting':
      showArea = true
      actionSpecificFields = getPlantingAreaFormConfig({
        ...formConfigParams,
        actionType,
      }) as FormConfig<FeatureFormFields>
      break
    case 'sitePreparation':
    case 'sitePreparationSpotSpraying':
    case 'maintenanceSpraying':
    case 'maintenanceSpotSpraying':
      showArea = true
      actionSpecificFields = getSiteMaintenanceFormConfig({
        ...formConfigParams,
        actionType,
      }) as FormConfig<FeatureFormFields>
      break
    case 'fundedFencing':
      showLength = true
      actionSpecificFields = getFencingFormConfig(formConfigParams) as FormConfig<FeatureFormFields>
      break
    default:
      throw new Error(`No form config for the given action type (${actionType}) and form mode (${formMode})`)
  }

  let config: FormConfig<FeatureFormFields> = [
    {
      renderOnlyWhen: isVerificationMode,
      type: 'field',
      element: {
        fieldId: 'inspectionDate',
        type: 'date',
        isRequired: true,
      },
    },
    {
      renderOnlyWhen: !isVerificationMode && isMapMode && !properties?.readOnly && !!properties?.hasVariation,
      type: 'atom',
      element: {
        type: 'inline-notification',
        messageType: 'warning',
        content: 'This action has a variation at sign-off. Any modifications to shape will be ignored on this map.',
        isDismissable: false,
      },
    },
    {
      renderOnlyWhen: isVerificationMode,
      type: 'field',
      element: {
        fieldId: 'completionStatus',
        type: 'lookup',
        lookupId: 'actionCompletionStatus',
        isRequired: true,
        watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<FeatureFormFields>) => {
          if (info.type === 'change' && info.name === 'completionStatus' && !data.hasVariation) {
            const isCompleted = isActionCompleted(data)
            methods.setValue('actualCost', isCompleted ? data.proposedCost : 0)
            methods.setValue('actualNumberOfPlants', isCompleted ? data.numberOfPlants : 0)
          }
          return initialConfig
        },
      },
    },
    {
      renderOnlyWhen: isVerificationMode,
      type: 'field',
      element: {
        fieldId: 'completionDetails',
        type: 'text-area',
        isRequired: true,
      },
    },
    {
      type: 'field',
      renderOnlyWhen: isVerificationMode && PLANTING_TYPES.includes(actionType),
      element: {
        fieldId: 'estimatedPlantSurvivalPercentage',
        type: 'number',
      },
    },
    {
      renderOnlyWhen: isVerificationMode,
      type: 'field',
      element: {
        fieldId: 'hasVariation',
        type: 'boolean',
      },
    },
    {
      renderOnlyWhen: isVerificationMode && isMapMode,
      type: 'atom',
      element: {
        type: 'inline-notification',
        messageType: 'warning',
        content: `Changes to the mapped action cannot be saved unless '${labels.hasVariation}' is checked 'yes'.`,
        isDismissable: false,
      },
    },
    {
      renderOnlyWhen: isVerificationMode && !isMapMode && showArea,
      type: 'field',
      element: {
        fieldId: 'area',
        type: 'read-only',
        formPrefix: 'actionVerification',
      },
    },
    {
      renderOnlyWhen: isVerificationMode && !isMapMode && showLength,
      type: 'field',
      element: {
        fieldId: 'length',
        type: 'read-only',
        formPrefix: 'actionVerification',
      },
    },
    {
      renderOnlyWhen: isVerificationMode && PLANTING_TYPES.includes(actionType),
      type: 'field',
      element: {
        fieldId: 'actualNumberOfPlants',
        type: 'text',
        helpText: 'If this differs to what was initially contracted',
      },
    },
    {
      renderOnlyWhen: isVerificationMode && ['maintenanceSpraying', 'sitePreparation'].includes(actionType),
      type: 'field',
      element: {
        fieldId: 'actualMaintenanceType',
        labelOverride: ['sitePreparation', 'sitePreparationSpotSpraying'].includes(actionType)
          ? 'Actual preparation type'
          : 'Actual maintenance type',
        type: 'lookup',
        lookupId:
          MAINTENANCE_LOOKUP_ID_MAP[
            actionType as
              | 'maintenanceSpraying'
              | 'maintenanceSpotSpraying'
              | 'sitePreparation'
              | 'sitePreparationSpotSpraying'
          ],
        isRequired: true,
        fieldFilters:
          actionType === 'maintenanceSpraying' && properties?.application?.value
            ? { applicationId: properties.application.value }
            : undefined,
      },
    },
    {
      renderOnlyWhen: isVerificationMode && ['fundedFencing'].includes(actionType),
      type: 'field',
      element: {
        fieldId: 'actualFenceType',
        type: 'lookup',
        lookupId: 'fence_type',
        required: true,
      },
    },
    {
      renderOnlyWhen: isVerificationMode,
      type: 'field',
      element: {
        fieldId: 'actualCost',
        type: 'number',
        step: '0.01',
        helpText: [
          'If the landowner has done less work than contracted, use the price',
          'schedule to figure out the actual cost of what has been completed.',
        ].join(' '),
      },
    },
    {
      renderOnlyWhen: formMode === FeatureFormMode.VERIFICATION_FORM,
      type: 'atom',
      element: { type: 'divider' },
    },
    {
      renderOnlyWhen: formMode === FeatureFormMode.VERIFICATION_FORM,
      type: 'atom',
      element: { type: 'heading', content: 'Photos and documents', level: 2 },
    },
    {
      renderOnlyWhen: formMode === FeatureFormMode.VERIFICATION_FORM,
      type: 'field',
      element: {
        type: 'files',
        fieldId: 'files',
      },
    },
    {
      renderOnlyWhen: formMode === FeatureFormMode.VERIFICATION_FORM,
      type: 'atom',
      element: { type: 'divider' },
    },
    {
      renderOnlyWhen: isVerificationMode,
      type: 'atom',
      element: {
        type: 'heading',
        content: 'Proposed action details',
        level: formMode === FeatureFormMode.VERIFICATION_FORM ? 2 : 4,
      },
    },
    {
      renderOnlyWhen: !isMapMode,
      type: 'field',
      element: {
        fieldId: 'actionType',
        type: 'read-only',
      },
    },
    {
      renderOnlyWhen: !isMapMode,
      type: 'field',
      element: {
        fieldId: 'actionSubtype',
        type: 'read-only',
      },
    },
    ...actionSpecificFields,
    {
      type: 'atom',
      element: { type: 'divider' },
    },
    {
      type: 'atom',
      element: {
        type: 'heading',
        level: !isMapMode ? 2 : 5,
        content: 'Photos and documents',
      },
    },
    {
      type: 'field',
      element: {
        type: 'files',
        fieldId: 'files',
      },
    },
  ] as FormConfig<FeatureFormFields>

  // Display read only form if view mode or if the application is approved in plan map or form mode
  if (routeMode === RouteMode.VIEW || (!isVerificationMode && properties?.readOnly)) {
    config = config.map((c) => convertElementToView(c)) as FormConfig<FeatureFormFields>
  }

  // Add disable watch logic for the variation form.
  config = config.map((c) => {
    if (
      'fieldId' in c.element &&
      ['actualCost', 'actualMaintenanceType', 'actualFenceType', 'actualNumberOfPlants'].includes(
        c.element.fieldId ?? '',
      )
    ) {
      return {
        ...c,
        element: { ...c.element, ...initiallyRequiredValidationConfig, watch: disableIfNoVariation },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as FormElementConfig<any>
    }
    return c
  }) as FormConfig<FeatureFormFields>

  function disableIfNoVariation({
    data,
    initialConfig,
    info,
    methods, // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }: FieldWatchResultProps<any>): FConfig<GetConfigForPlanActionType> {
    if (info.type === 'change' && info.name === 'hasVariation') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const fieldId = (initialConfig as LibLookupFieldProps<any> | TextFieldProps<any>).fieldId
      const isCompleted = isActionCompleted(data)

      if (fieldId === 'actualCost') {
        methods.setValue(fieldId, isCompleted ? data.proposedCost : 0)
      }
      if (fieldId === 'actualNumberOfPlants') {
        methods.setValue(fieldId, isCompleted ? data.numberOfPlants : 0)
      }

      return {
        ...initialConfig,
        isRequired: data.hasVariation,
        isDisabled: !data.hasVariation,
      } as LibLookupFieldProps<GetConfigForPlanActionType> | TextFieldProps<GetConfigForPlanActionType>
    }

    return initialConfig
  }

  return config
}
