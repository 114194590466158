import { SectionConfig, TabItemProps } from '@msaf/core-react'
import { INITIAL_STATUS, LOCK_TO_EOI_STATUSES } from '../constants'
import { WorkflowState } from '../validation/constants'
import { hasFormBeenCompleted } from '../validation/utils'

export type ApplicationsState = { id: string; applicationNo: string; isSubmitted: boolean }

export function usePlanWorkflowLinks(
  id: string | undefined,
  mode: 'edit' | 'view' | undefined,
  workflowState?: WorkflowState<unknown>,
  applications?: Array<ApplicationsState>,
): Array<SectionConfig> {
  if (!id || !mode) return []

  const planFormState = workflowState?.plan?.[id]?.state?.planCreation?.forms

  const links: SectionConfig[] = [
    {
      heading: 'Plan creation',
      links: [
        {
          path: `/plans/${mode}/${id}/plan/pre-checks`,
          label: 'Pre-checks',
          checked: hasFormBeenCompleted(planFormState?.preChecks),
        },
        {
          path: `/plans/${mode}/${id}/plan/applicant`,
          label: 'Applicant',
          checked: hasFormBeenCompleted(planFormState?.applicant),
        },
        {
          path: `/plans/${mode}/${id}/plan/catchment-context`,
          label: 'Catchment context',
          checked: hasFormBeenCompleted(planFormState?.catchmentContext),
        },
        {
          path: `/plans/${mode}/${id}/plan/property-systems-aspirations`,
          label: 'Property systems & aspirations',
          checked: hasFormBeenCompleted(planFormState?.propertySystemsAspirations),
        },
        {
          path: `/plans/${mode}/${id}/plan/system-aspirations`,
          label: 'Risks & opportunities',
          checked: hasFormBeenCompleted(planFormState?.systemAspirations),
        },
        {
          path: `/plans/${mode}/${id}/plan/overview`,
          label: 'Plan overview',
          checked: hasFormBeenCompleted(planFormState?.overview),
        },
      ],
    },
  ]

  if (Array.isArray(applications)) {
    return links.concat(
      applications.map(({ id: applicationId, applicationNo }) => ({
        heading: applicationNo,
        links: [
          { path: `/plans/${mode}/${id}/plan/project/${applicationId}/details`, label: 'Details & budget' },
          { path: `/plans/${mode}/${id}/plan/project/${applicationId}/submission`, label: 'Submission' },
        ],
      })),
    )
  }
  return links
}

type PlanWorkflowOptions = {
  id?: string
  hiddenTabs?: Array<WorkflowTabs>
  status: string
  currentPath: string
  mode: string
}

export enum WorkflowTabs {
  EOI = 'Expression of interest',
  PLAN = 'Plan',
  MAP = 'Map',
  REVIEW = 'Review & Approval',
  SUPPLY = 'Supply',
  PROGRESS = 'Progress',
  PAYMENTS = 'Payments',
  HISTORY = 'History',
}

type WorkflowTabConfig = {
  [key in WorkflowTabs]?: TabItemProps
}

export const getPlanWorkflowTabs = ({
  id,
  status,
  mode,
  currentPath,
  hiddenTabs,
}: PlanWorkflowOptions): Array<TabItemProps> => {
  if (id == null) {
    return [
      {
        label: WorkflowTabs.EOI,
        path: `/plans/new`,
      },
    ]
  }

  // Initialise map
  let tabConfig: WorkflowTabConfig = {
    [WorkflowTabs.EOI]: { label: WorkflowTabs.EOI, path: `/plans/${mode}/${id}/eoi` },
  }
  if (status === WorkflowTabs.EOI) {
    tabConfig = {
      ...tabConfig,
      [WorkflowTabs.MAP]: { label: WorkflowTabs.MAP, path: `/plans/base/${id}/map?origin=${currentPath}` },
    }
  }

  if (status !== INITIAL_STATUS && !LOCK_TO_EOI_STATUSES.includes(status)) {
    tabConfig = {
      ...tabConfig,
      [WorkflowTabs.PLAN]: { label: WorkflowTabs.PLAN, path: `/plans/${mode}/${id}/plan` },
      [WorkflowTabs.MAP]: { label: WorkflowTabs.MAP, path: `/plans/${mode}/${id}/map?origin=${currentPath}` },
      [WorkflowTabs.REVIEW]: { label: WorkflowTabs.REVIEW, path: `/plans/${mode}/${id}/review` },
      [WorkflowTabs.SUPPLY]: { label: WorkflowTabs.SUPPLY, path: `/plans/${mode}/${id}/supply`, isNewSection: true },
      [WorkflowTabs.PROGRESS]: { label: WorkflowTabs.PROGRESS, path: `/plans/${mode}/${id}/progress` },
      [WorkflowTabs.PAYMENTS]: { label: WorkflowTabs.PAYMENTS, path: `/plans/${mode}/${id}/payments` },
      [WorkflowTabs.HISTORY]: { label: WorkflowTabs.HISTORY, path: `/plans/${mode}/${id}/history`, isNewSection: true },
    }
  }

  // Remove hidden tabs
  hiddenTabs?.forEach((tab) => delete tabConfig[tab])

  return Object.values(tabConfig)
}
