import { usePageState } from '@msaf/core-react'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { RouteParams } from '../types/route'
import { useRequest } from './useRequest'
import { usePlanWarning } from './use-plan-warnings'
export interface PlanSummaryData {
  status: string
  statusDays: string
  heading: string
  location: [number, number] | null
  isMigrated: boolean
  hasEoi: boolean
  reopenedApplications: string[]
}

export const usePlanSummary = () => {
  const { id } = useParams<RouteParams>()
  const { setPageState } = usePageState()
  const { client } = useRequest()

  const { data, dataUpdatedAt, refetch, isLoading } = useQuery<PlanSummaryData>(
    [id, 'summary'],
    () => client.get(`/api/plan/${id}/summary`).then((res) => res.data),
    {
      // Keep previous data while we fetch new data
      keepPreviousData: true,
    },
  )

  const { warnings } = usePlanWarning()

  useEffect(() => {
    if (data) {
      const tags = [] as string[]
      if (data.status) {
        if (data.statusDays) {
          tags.push(`${data.status} (${data.statusDays} days)`)
        } else {
          tags.push(data.status)
        }
      }

      const levels = warnings?.map((w) => w.level)
      if (levels?.includes('ERROR')) {
        tags.push('Limits exceeded')
      } else if (levels?.includes('WARNING')) {
        tags.push('Approaching limits')
      }

      if (data.reopenedApplications) tags.push(...data.reopenedApplications.map((a) => `Reopened Project ${a}`))

      setPageState((prevState) => ({
        ...prevState,
        heading: data.heading,
        tags,
      }))
    }
    return () => setPageState((prevState) => ({ ...prevState, heading: undefined, tags: undefined }))
    // Instead of updating based on `data` change, update when the dataUpdatedAt timestamp changes.
  }, [setPageState, dataUpdatedAt])

  return {
    status: data?.status,
    location: data?.location,
    isMigratedPlan: data?.isMigrated,
    hasEoi: data?.hasEoi,
    refetch,
    isLoading,
  }
}
