import { removeDuplicateSlashes } from './services/urls'

const CLIENT_ADDRESS = removeDuplicateSlashes(process.env.REACT_APP_CLIENT_ADDRESS ?? 'http://localhost:3000')
const SERVER_ADDRESS = removeDuplicateSlashes(process.env.REACT_APP_SERVER_ADDRESS ?? 'http://localhost:8000')
const SEARCH_API_URL = `${SERVER_ADDRESS}/api/generic-search`
const LINZ_KEY = process.env.REACT_APP_LINZ_KEY ?? ''
const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? ''
const KEYCLOAK = {
  ADDRESS: removeDuplicateSlashes(process.env.REACT_APP_KEYCLOAK_ADDRESS ?? 'http://localhost:8080'),
  CLIENT_ID: process.env.REACT_APP_KEYCLOAK_FRONTEND_CLIENT_ID ?? 'frontend',
  REALM: process.env.REACT_APP_KEYCLOAK_MAIN_REALM ?? 'main',
  // Default to S256, allow override for local dev
  PKCE_METHOD: (process.env.REACT_APP_KEYCLOAK_PKCE_METHOD as 'S256' | undefined) ?? 'S256',
}

export { CLIENT_ADDRESS, SERVER_ADDRESS, SEARCH_API_URL, LINZ_KEY, KEYCLOAK, MAPBOX_ACCESS_TOKEN }
