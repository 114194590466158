import { MAPBOX_ACCESS_TOKEN } from '../../environment'
import * as L from 'leaflet'

interface MapboxLayerConfig {
  name: string
  styleId?: string
  tilesetId?: string
  enabled?: boolean
  options: L.TileLayerOptions
}

const mapboxLayersConfig: MapboxLayerConfig[] = [
  {
    name: 'Soils',
    styleId: 'cli3mhynj00wa01r80d37fupx',
    options: {
      zIndex: 2,
    },
  },
  {
    name: 'Sediment capture zones',
    styleId: 'cli3mhxpu007h01rbax73hhyu',
    options: {
      zIndex: 3,
    },
  },
  {
    name: 'Indicative sediment risk layers',
    styleId: 'cli3mhx82002701o820gvd1xw',
    options: {
      zIndex: 4,
    },
  },
  {
    name: 'Vegetation and important natural areas',
    styleId: 'cli3mhytm00vb01r55x5mhnot',
    options: {
      zIndex: 5,
    },
  },
  {
    name: 'Land use capability',
    styleId: 'cli3mhyu800vc01r50agg7o3h',
    options: {
      zIndex: 6,
    },
  },
  {
    name: 'Ecological districts',
    styleId: 'cli3mhyqu00xs01rh3kxc8orv',
    options: {
      zIndex: 7,
    },
  },
  {
    name: 'Surface water catchments',
    styleId: 'cli3mhxnx00xa01po859abkpx',
    options: {
      zIndex: 8,
    },
  },
  {
    // This tileset is only raster image data, and Mapbox styles returns jpeg tiles
    // in this instance. So we need to request the tiles directly as png's so we can apply
    // opacity, see use of tilesetId in buildLayersObject.
    name: 'Landscape terrain',
    tilesetId: 'kaipara-moana-remediation.hillshade',
    options: {
      maxNativeZoom: 16,
      opacity: 0.55,
      zIndex: 1,
    },
  },
  {
    name: 'Marae',
    styleId: 'cli3mhxnf00kd01rfeb5h4evx',
    enabled: false,
    options: {
      zIndex: 12,
    },
  },
  {
    name: 'Māori land court data 2017',
    styleId: 'clmfhko3f017601rabps34ct3',
    enabled: false,
    options: {
      zIndex: 12,
    },
  },
  {
    name: 'NRC Slope Class',
    styleId: 'cli3mhxby00ul01pzhzkr8ac0',
    enabled: false,
    options: {
      zIndex: 8,
    },
  },
  {
    name: 'MFE Slope Class',
    styleId: 'cli3mhx7f00ak01r62z7m75un',
    enabled: false,
    options: {
      zIndex: 8,
    },
  },
  {
    name: 'KMR Slope Class',
    styleId: 'cli3mhx7v00v101q1gns728cb',
    enabled: false,
    options: {
      zIndex: 8,
    },
  },
  {
    name: 'Freshwater Management Units',
    styleId: 'clrtwv0br006z01pu1f3804ot',
    enabled: false,
    options: {
      zIndex: 12,
      minZoom: 5,
    },
  },
  {
    name: 'Territorial Authorities',
    styleId: 'clru1nx52006w01r6d8v98q4w',
    enabled: false,
    options: {
      attribution:
        '<a target="_blank" href="https://datafinder.stats.govt.nz/layer/111194-territorial-authority-2023-generalised/">Territorial Authority 2023 (generalised)</a> by <a target="_blank" href="https://datafinder.stats.govt.nz/">Stats NZ</a> is licensed under <a target="_blank" href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a>',
      zIndex: 13,
      minZoom: 5,
    },
  },
  {
    name: 'Regional Councils',
    styleId: 'clru0mmpg006g01pv83ee9bse',
    enabled: false,
    options: {
      attribution:
        '<a target="_blank" href="https://datafinder.stats.govt.nz/layer/111182-regional-council-2023-generalised/">Regional Council 2023 (generalised)</a> by <a target="_blank" href="https://datafinder.stats.govt.nz/">Stats NZ</a> is licensed under <a target="_blank" href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a>',
      zIndex: 14,
      minZoom: 5,
    },
  },
  {
    name: 'Land boundaries (owner/title)',
    styleId: 'clyzao9zq00ch01pxa4to4dxg',
    enabled: false,
    options: {
      attribution:
        '<a target="_blank" href="//data.linz.govt.nz/license/linz-licence-personal-data-21">LINZ Licence for Personal Data 2.1</a>',
      zIndex: 10,
    },
  },
  {
    name: 'Roads',
    styleId: 'cli3mhyrr00ke01rf5nl8acho',
    enabled: false,
    options: {
      zIndex: 11,
    },
  },
  {
    name: 'River network',
    styleId: 'cli3mhzc0008301o7cmnm1ue0',
    enabled: true,
    options: {
      zIndex: 9,
    },
  },
  {
    name: 'Existing works (including old KMR GIS and Fonterra)',
    styleId: 'clymij57r035s01prepkb53nm',
    enabled: false,
    options: {
      zIndex: 15,
      minZoom: 5,
    },
  },
  {
    name: 'KMR boundary',
    styleId: 'clxcqj7o6029c01rdc5ite1it',
    enabled: false,
    options: {
      zIndex: 99,
      minZoom: 5,
    },
  },
]

// Define any basemaps here in the same structure as the layer config above
const mapboxBasemapsConfig: MapboxLayerConfig[] = []

const buildLayersObject = (configList: MapboxLayerConfig[]): L.Control.LayersObject => {
  const defaultOptions = { maxZoom: 22, minZoom: 12, tileSize: 512, zoomOffset: -1 }

  return configList.reduce((layers, config) => {
    const options = { ...defaultOptions, ...config.options }
    const styleUrl = `https://api.mapbox.com/styles/v1/kaipara-moana-remediation/${config.styleId}/tiles/{z}/{x}/{y}@2x?access_token=${MAPBOX_ACCESS_TOKEN}`
    const tilesetUrl = `https://api.mapbox.com/v4/${config.tilesetId}/{z}/{x}/{y}@2x.png?access_token=${MAPBOX_ACCESS_TOKEN}`
    const url = config.tilesetId ? tilesetUrl : styleUrl
    layers[config.name] = L.tileLayer(url, options)
    return layers
  }, {} as L.Control.LayersObject)
}

export const mapboxBasemaps = (): L.Control.LayersObject => buildLayersObject(mapboxBasemapsConfig)
export const mapboxLayers = (): L.Control.LayersObject => buildLayersObject(mapboxLayersConfig)
export const defaultLayerNames = (): string[] =>
  mapboxLayersConfig.filter((config) => !!config.enabled).map((config) => config.name)
