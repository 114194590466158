import {
  ButtonOnlyIcon,
  ButtonWithIcon,
  ButtonWithoutIcon,
  DateFieldProps as CoreDateFieldProps,
  InlineNotificationProps,
  NewFile,
} from '@msaf/core-react'
import { FeatureCollection, Geometry } from '@turf/helpers'
import { ReactNode } from 'react'
import { ArrayPath, FieldArray, FieldValues, Path, PathValue, UnpackNestedValue } from 'react-hook-form'
import { LookupSearchProps } from '../components/lookup-typeahead'
import { CrudLabels } from '../components/lookups/crud'
import { SummaryTableProps } from '../components/summary-table'
import { LookupItem } from '../types'
import { NoteForm } from '../types/notes'
import { RouteMode } from '../types/route'
import { ControlledFileManagerProps, UploadedFile } from './components/file-controller'
import { UseFieldWatchFunc } from './components/hooks/use-field-watch'
import { ConfirmActionProps } from './components/modals/confirm-action'
import { ActionVerificationFields } from '../routes/plans/[mode]/[id]/application/[applicationId]/progress/action-verification'
import { FeatureType } from '../config/map'

export const FeatureFormMode = {
  EDIT_FORM: 'EditForm',
  MAIN_MAP: 'MainMap',
  VERIFICATION_FORM: 'Verification',
  VERIFICATION_MAP: 'VerificationMap',
} as const
export type FeatureFormModeKeys = (typeof FeatureFormMode)[keyof typeof FeatureFormMode]

export type ExpressionOfInterestFormIdTypes = 'newRegistration' | 'decision'
export type PlanFormIdTypes =
  | 'preChecks'
  | 'applicant'
  | 'propertySystemsAspirations'
  | 'catchmentContext'
  | 'systemAspirations'
  | 'overview'
export type ApplicationFormIdTypes = 'details' | 'action'
export type SupplyFormIdTypes = 'nursery' | 'contract'
export type ReviewFormIdTypes = 'review' | 'panelistReview' | 'approval'
export type PaymentFormIdTypes = 'requestsForPaymentSummary' | 'requestsForPayment'
export type ProgressFormIdTypes = 'actionVerification' | 'signOff'
export type FormIdTypes =
  | ExpressionOfInterestFormIdTypes
  | PlanFormIdTypes
  | ApplicationFormIdTypes
  | SupplyFormIdTypes
  | ReviewFormIdTypes
  | PaymentFormIdTypes
  | ProgressFormIdTypes

export type SupportedFormFieldTypes =
  | 'text'
  | 'text-area'
  | 'email'
  | 'number'
  | 'date'
  | 'boolean'
  | 'lookup'
  | 'checkbox'
  | 'radio'
  | 'checkable-group'
  | 'read-only'
  | 'notes'
  | 'address-lookup'
  | 'person-crud'
  | 'organisation-crud'
  | 'files'
  | 'inline-map'

export type TextType = 'text' | 'email' | 'number'

export interface FormAwareField<T extends FieldValues> {
  watch?: UseFieldWatchFunc<T>
  isHidden?: boolean
  isSkeleton?: boolean
  validationDisabled?: boolean
}

export type HelpTextType = string | ReactNode

export interface BaseFieldConfig<T extends FieldValues> extends FormAwareField<T> {
  fieldId: Path<T>
  mode?: RouteMode
  isRequired?: boolean
  isDisabled?: boolean
  isHidden?: boolean
  type: SupportedFormFieldTypes
  helpText?: HelpTextType
}

export interface LabelledFieldConfig<T extends FieldValues> extends BaseFieldConfig<T> {
  labelOverride?: string
  // Used for when we have multiple fields with the same label in different forms
  formPrefix?: string
}

export interface RepeatingLabelledFieldConfig<T extends FieldValues> extends LabelledFieldConfig<T> {
  repeating: true
  defaultValue: UnpackNestedValue<FieldArray<T, ArrayPath<T>>>
}

export type HeadingConfig = { type: 'heading'; level: 2 | 3; content: string }
export type ParagraphConfig = { type: 'paragraph'; content: string; className?: string }
export type InlineNotificationConfig = { type: 'inline-notification'; content: ReactNode } & InlineNotificationProps
export type DividerConfig = { type: 'divider' }
export type PlanWarnings = { type: 'plan-warnings' }

export type DisplayFilteredConfig = {
  renderOnlyWhen?: boolean
}

export type CardConfig<T extends FieldValues> = {
  type: 'card'
  fields: (AtomConfig<T> | ActionConfig<T> | ModalConfig<T> | FormFieldConfig<T>)[]
}

export type AtomConfig<T extends FieldValues> = DisplayFilteredConfig & {
  type: 'atom'
  element: HeadingConfig | DividerConfig | CardConfig<T> | ParagraphConfig | InlineNotificationConfig | PlanWarnings
}

export type ActionConfig<T extends FieldValues> = DisplayFilteredConfig & {
  type: 'action'
  element: ButtonProps<T> & { enabledInViewMode?: boolean }
}

export type FormFieldConfig<T extends FieldValues> = DisplayFilteredConfig & {
  type: 'field'
  element: FieldConfig<T> | ComplexFieldConfig<T>
}

export type ModalConfig<T extends FieldValues> = DisplayFilteredConfig & {
  type: 'modal'
  element: ConfirmActionModal<T>
}

export interface ButtonProps<T extends FieldValues> extends ButtonWithIcon, FormAwareField<T> {
  fieldId?: Path<T>
  type: 'button'
}

export interface TextFieldProps<T extends FieldValues> extends LabelledFieldConfig<T> {
  type: TextType
  min?: number
  max?: number
  step?: string
  placeholder?: string
}

export interface RepeatingTextFieldProps<T extends FieldValues> extends TextFieldProps<T> {
  repeating?: boolean
  defaultValue: UnpackNestedValue<FieldArray<T, ArrayPath<T>>>
}

export interface TextAreaFieldProps<T extends FieldValues> extends LabelledFieldConfig<T> {
  type: 'text-area'
  placeholder?: string
}

export interface BooleanFieldProps<T extends FieldValues> extends LabelledFieldConfig<T> {
  type: 'boolean'
}

export interface LibLookupFieldProps<T extends FieldValues>
  extends LabelledFieldConfig<T>,
    Omit<Pick<LookupSearchProps, 'lookupId' | 'fieldFilters' | 'isClearable'>, 'label'> {
  type: 'lookup'
  repeating?: false
  defaultToFirst?: boolean
}

export interface RepeatingLibLookupFieldProps<T extends FieldValues>
  extends RepeatingLabelledFieldConfig<T>,
    Pick<LookupSearchProps, 'lookupId' | 'fieldFilters' | 'isClearable'> {
  type: 'lookup'
  defaultToFirst?: boolean
  allowDuplicates?: boolean
}

export interface CrudFieldProps<T extends FieldValues> extends BaseFieldConfig<T> {
  labels: CrudLabels
}

export interface PersonCrudFieldProps<T extends FieldValues> extends CrudFieldProps<T> {
  type: 'person-crud'
}

export interface OrganisationCrudFieldProps<T extends FieldValues> extends CrudFieldProps<T> {
  type: 'organisation-crud'
}

export interface AddressLookupFieldProps<T extends FieldValues> extends LabelledFieldConfig<T> {
  type: 'address-lookup'
}

export interface RepeatingAddressLookupFieldProps<T extends FieldValues> extends RepeatingLabelledFieldConfig<T> {
  type: 'address-lookup'
}

export interface DateFieldProps<T extends FieldValues>
  extends LabelledFieldConfig<T>,
    Partial<
      Pick<
        CoreDateFieldProps,
        | 'maxDate'
        | 'minDate'
        | 'showYearPicker'
        | 'dateFormat'
        | 'yearItemNumber'
        | 'showMonthYearPicker'
        | 'dateFormatCalendar'
        | 'isDisabled'
      >
    > {
  type: 'date'
}

export interface CheckableFieldProps<T extends FieldValues> extends LabelledFieldConfig<T> {
  type: 'checkbox' | 'radio'
}

export interface ReadOnlyFieldProps<T extends FieldValues> extends LabelledFieldConfig<T> {
  type: 'read-only'
  conversion?: (value: UnpackNestedValue<PathValue<T, Path<T>>>) => string
}

export interface CheckableFieldGroupProps<T extends FieldValues> extends FormAwareField<T> {
  type: 'checkable-group'
  label: string
  labelId: string
  helpText?: HelpTextType
  fields: CheckableFieldProps<T>[]
  mode?: RouteMode
}

// fieldId will be derived from the parent field Id
type RepeatingSectionFieldConfig<T extends FieldValues> = Omit<LabelledFieldConfig<T>, 'fieldId'> & {
  fieldId?: string
  lookupId?: string
  fieldFilters?: Record<string, string | number | boolean | null>
  label?: string
  min?: number
}

export interface RepeatingSectionProps<T extends FieldValues> extends FormAwareField<T> {
  type: 'repeating-section'
  heading: string
  sectionName: string
  fieldId: Path<T>
  fields: Array<RepeatingSectionFieldConfig<T>>
  repeating?: boolean
  helpText?: string
  mode?: RouteMode
  isSkeleton?: boolean
  disableNumberedSections?: boolean
  allowEmpty?: boolean
  error?: string
}

export interface TableProps<T extends FieldValues> extends FormAwareField<T>, SummaryTableProps<T> {
  type: 'table'
}

export interface ConfirmActionModal<T extends FieldValues> extends FormAwareField<T>, ConfirmActionProps<T> {
  type: 'confirm-action'
}

export interface NotesFieldProps<T extends FieldValues> extends FormAwareField<T> {
  type: 'notes'
  fieldId: Path<T>
  form: NoteForm
  mode?: RouteMode
}

export interface FileFieldProps<T extends FieldValues>
  extends FormAwareField<T>,
    Omit<ControlledFileManagerProps<T>, 'control' | 'name' | 'isEditable'> {
  type: 'files'
  fieldId: Path<T>
  mode?: RouteMode
  uploadFileAction?: (newFile: NewFile) => Promise<Omit<UploadedFile, 'state'>>
  isDisabled?: boolean
}

export interface InlineFeatureMapFieldProps<T extends FieldValues> extends FormAwareField<T> {
  type: 'map'
  mode?: RouteMode
  center: [number, number]
  zoom: number
  actions?: Array<ButtonWithIcon | ButtonWithoutIcon | ButtonOnlyIcon>
  featuresUrl: string
  filterFeatureTypes: string[]
}

export interface StaticInlineFeatureMapFieldProps<T extends FieldValues> extends FormAwareField<T> {
  type: 'static-map'
  mode?: RouteMode
  features?: FeatureCollection<Geometry>
  zoom: number
  actions?: Array<ButtonWithIcon | ButtonWithoutIcon | ButtonOnlyIcon>
}

export type FieldConfig<T extends FieldValues> =
  | TextFieldProps<T>
  | TextAreaFieldProps<T>
  | BooleanFieldProps<T>
  | LibLookupFieldProps<T>
  | RepeatingLibLookupFieldProps<T>
  | DateFieldProps<T>
  | ReadOnlyFieldProps<T>
  | AddressLookupFieldProps<T>
  | RepeatingAddressLookupFieldProps<T>
  | PersonCrudFieldProps<T>
  | OrganisationCrudFieldProps<T>
  | RepeatingTextFieldProps<T>

export type ComplexFieldConfig<T extends FieldValues> =
  | CheckableFieldGroupProps<T>
  | NotesFieldProps<T>
  | RepeatingSectionProps<T>
  | FileFieldProps<T>
  | TableProps<T>
  | InlineFeatureMapFieldProps<T>
  | StaticInlineFeatureMapFieldProps<T>

export interface BaseFeatureFormConfig {
  actionType: FeatureType
  actionSubtype: string // TODO: Make this strongly typed
  application: LookupItem | null
  files?: UploadedFile[]
}

export interface GateTroughFormFields extends BaseFeatureFormConfig {
  locationName: string | null
  anticipatedStart: string | null
  justification: string | null
  intendedImplementer: Partial<LookupItem> | null
  unitCount: number | null
  materialPrice: number | null
}

export interface PlantingAreaFormFields extends BaseFeatureFormConfig {
  area: number | null
  locationName: string | null
  regenerationLocationNotes: string | null
  anticipatedStart: string | null
  plantingDifficulty: Partial<LookupItem>
  justification: string | null
  intendedImplementer: Partial<LookupItem>
  averagePricePerPlant: number | null
  plantingZoneAWetlandPercent: number | null
  plantingZoneBLowerBankPercent: number | null
  plantingZoneCTransitionPercent: number | null
  numberOfPlants: number | null
  numberOfPlantsZoneA: number | null
  numberOfPlantsZoneB: number | null
  numberOfPlantsZoneC: number | null
  numberOfPlantsZoneD: number | null
  labourCost: number | null
  materialCost: number | null
  proposedCost: number | null
  plantingSpacingStemsPerHa: number | null
  estimatedPricePerHa: number | null
  kmrContributionPerHa: number | null
  plantingSpeciesComposition: string | null
  plantingManagementIntentions: number | null
  silvopasturePlantingMethod: string | null
}

export interface SiteMaintenanceFormFields extends BaseFeatureFormConfig {
  area: string | null
  locationName: string | null
  anticipatedStart: string | null
  justification: string | null
  intendedImplementer: Partial<LookupItem>
  maintenanceType: Partial<LookupItem>
  materialPrice: string | null
  numberOfPlantsRequiringSpraying: number | null
  sprayCount: number | null
  proposedCost: string | null
}
export interface FencingFormFields extends BaseFeatureFormConfig {
  locationName: string | null
  anticipatedStart: string | null
  terrainDifficulty: Partial<LookupItem>
  fenceType: Partial<LookupItem>
  actualFenceType: Partial<LookupItem>
  justification: string | null
  intendedImplementer: Partial<LookupItem>
  length: string | null
  actualLength: string | null
  materialPrice: string | null
  proposedCost: string | null
}

export type FeatureFormFields = ActionVerificationFields &
  PlantingAreaFormFields &
  GateTroughFormFields &
  SiteMaintenanceFormFields &
  FencingFormFields

export interface ProposedTotals {
  siteMaintenanceArea: string
  siteMaintenanceCost: string
  sitePreparationArea: string
  sitePreparationCost: string
  plantingArea: string
  plantingCost: string
  plantingPlantCost: string
  plantingLabourCost: string
  plantingPlantCount: string
  fencingLength: string
  fencingCost: string
  proposedGatesUnits: string
  proposedGatesCost: string
  proposedTroughUnits: string
  proposedTroughCost: string
  totalProposedCost: string
  landOwnerContribution: string
  kmrContribution: string
  totalCoFunderContribution: string
}

export type FormElementConfig<T extends FieldValues> =
  | ActionConfig<T>
  | AtomConfig<T>
  | FormFieldConfig<T>
  | ModalConfig<T>

export type FormConfig<T extends FieldValues> = Array<FormElementConfig<T>>

export type CreateMigratedRecordResponse = {
  id: string
  planId: string
  isMigrated: boolean
}
