import { useLocation } from '@msaf/router-react'

import { useEffect, useMemo } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { WorkflowTabs, getPlanWorkflowTabs } from '../config/workflow-nav'
import { useBackLink } from '../hooks/useBackLink'
import { usePageHeaderTabs } from '../hooks/usePageHeaderTabs'
import { usePlanSummary } from '../hooks/usePlanSummary'
import { ApplicationRouteParams, RouteMode } from '../types/route'
import { ProjectActions } from './project-actions'

export const PlanPage = () => {
  const location = useLocation()
  const { id, mode, applicationId } = useParams<ApplicationRouteParams>()
  const { status, hasEoi, refetch: updateHeading } = usePlanSummary()

  useBackLink({ label: 'Dashboard', path: '/' })

  useEffect(() => {
    updateHeading()
  }, [location.pathname])

  const tabs = useMemo(() => {
    if (status == null || id == null) {
      return []
    }

    const hiddenTabs = !hasEoi ? [WorkflowTabs.EOI] : []

    return getPlanWorkflowTabs({
      id,
      status,
      currentPath: location.pathname,
      mode: mode ?? RouteMode.VIEW,
      hiddenTabs,
    })
  }, [location.pathname, status, applicationId])

  usePageHeaderTabs(tabs)

  return (
    <>
      {applicationId && <ProjectActions />}
      <Outlet />
    </>
  )
}
