import { createContext, PropsWithChildren, useContext } from 'react'
import { RefetchOptions } from 'react-query'

type PlanState = {
  isMigratedPlan?: boolean
  hasEditPermission: boolean
  refetchState?: (options?: RefetchOptions) => void
}

const PlanContext = createContext<PlanState | undefined>(undefined)

export default function PlanProvider({ children, planState }: PropsWithChildren<{ planState: PlanState }>) {
  return <PlanContext.Provider value={planState}>{children}</PlanContext.Provider>
}

function usePlanState() {
  const context = useContext(PlanContext)
  if (context === undefined) {
    throw new Error('usePlanState must be used within a PlanProvider')
  }
  return context
}

export { usePlanState }
