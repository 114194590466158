import { useParams } from 'react-router-dom'
import { FeatureMap } from '../../../../../../../../components/feature-map'
import { Gatekeeper, useViewRedirectGatekeeper } from '../../../../../../../../components/gatekeeper'
import NotFound from '../../../../../../../../components/not-found'
import { ApplicationStatusFields } from '../../submission'
import { useFormData } from '../../../../../../../../hooks/use-form-state'
import { EDIT_APPLICATION_PROGRESS_PERMISSIONS, UserPermissions } from '../../../../../../../../types/permissions'
import { ApplicationRouteParams } from '../../../../../../../../types/route'

export const ActionVerificationMap = () => {
  const { id, applicationId, mode } = useParams<ApplicationRouteParams>()
  useViewRedirectGatekeeper(EDIT_APPLICATION_PROGRESS_PERMISSIONS, UserPermissions.VIEW_PROGRESS)
  const applicationState = useFormData<ApplicationStatusFields>(`/api/plan/${id}/project/${applicationId}/status`)

  if (applicationState.data?.isMigrated) {
    return <NotFound />
  }

  return (
    <Gatekeeper>
      <FeatureMap
        featureToggle={{
          create: false,
          edit: true,
          delete: false,
        }}
        featuresUrl={`/api/plan/${id}/project/${applicationId}/features`}
        centerMode={'toFeatures'}
        actionUrlTemplate={`/plans/${mode}/${id}/progress/project/${applicationId}/action-verification/:actionId`}
      />
    </Gatekeeper>
  )
}
