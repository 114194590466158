import { InlineNotification, LoadingIndicator, RecordContent } from '@msaf/core-react'
import { Outlet, useParams } from 'react-router-dom'
import { useViewRedirectGatekeeper } from '../../../../components/gatekeeper'
import ApplicationProvider from '../../../../contexts/application-context'
import { ApplicationStatusFields } from './application/[applicationId]/submission'
import { useWorkflowGatekeeper } from '../../../../forms/application/utils'
import { useFormData } from '../../../../hooks/use-form-state'
import { UserPermissions } from '../../../../types/permissions'
import { ApplicationRouteParams } from '../../../../types/route'
import { useAddGenerateExportMenu } from '../../../../hooks/use-add-generate-export-menu'

export const SupplySectionWrapper = () => {
  const { id: planId, applicationId, mode } = useParams<ApplicationRouteParams>()
  const applicationState = useFormData<ApplicationStatusFields>(`/api/plan/${planId}/project/${applicationId}/status`)
  useViewRedirectGatekeeper(UserPermissions.EDIT_SUPPLY, UserPermissions.VIEW_SUPPLY)

  useAddGenerateExportMenu(applicationState.data)

  const { isLoading, open } = useWorkflowGatekeeper(planId, applicationId, 'supply', mode)

  if (isLoading) return <LoadingIndicator />

  if (!open) {
    return (
      <RecordContent>
        <InlineNotification isDismissable={false}>Not submitted</InlineNotification>
      </RecordContent>
    )
  }

  return (
    <ApplicationProvider applicationState={{ ...applicationState.data }}>
      <Outlet />
    </ApplicationProvider>
  )
}
