import { AdvancedSearchMap } from '@msaf/generic-search-maps-react'
import { useTitleChange } from '@msaf/core-react'
import { defaultIcon, getBaseLayers, selectedIcon } from '../../../config/map'
import { useAuth } from '../../../auth'
import { SEARCH_API_URL } from '../../../environment'

export function PlanMapSearch() {
  useTitleChange('Plan - Map search')
  const { authStrategy } = useAuth()

  if (!authStrategy) {
    throw Error('403 - This page is only able to be reached with authenticated access')
  }

  return (
    <AdvancedSearchMap<'token'>
      searchTypes={[
        {
          key: 'plan-search',
          label: 'SRPs',
        },
        {
          key: 'project-search',
          label: 'Projects',
        },
      ]}
      requestOptions={{
        urlPrefix: `${SEARCH_API_URL}`,
        authStrategy: 'token',
        authToken: authStrategy.token,
        authTokenScheme: authStrategy.tokenIdentifier,
      }}
      mapOptions={{
        baseLayers: getBaseLayers(),
      }}
      defaultIcon={defaultIcon}
      selectedMarkerIcon={selectedIcon}
      backLinkConfig={{
        path: '/plans',
        label: 'Back to list search',
        mobileLabel: 'Back',
      }}
      showMapPageHeader
      appLogo={{
        icon: 'map',
      }}
    />
  )
}
